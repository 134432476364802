import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class PrimaryButton extends Component {
  render() {
    const { className, disabled, label, onClick } = this.props
    return (
      <CFButton disabled={disabled} onClick={onClick}>
        <ThemeContext.Consumer>
          {({ Components }) => (
            <Components.PrimaryButtonView
              className={className}
              label={label}
              disabled={disabled}
            />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}
