import React, { Component } from 'react'
import { css } from 'emotion'
import { promotions, about, foodGallery, specialPromo } from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img
          src={specialPromo}
          className={styles.foodGallery}
          alt="Promotions"
        />
        <img src={promotions} className={styles.foodGallery} alt="Promotions" />
        <div className={styles.sectionBG}>
          <img src={about} className={styles.section} alt="About" />
        </div>
        <img
          src={foodGallery}
          className={styles.foodGallery}
          alt="Food Gallery"
        />
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '140rem',
    paddingTop: '2rem',
    paddingBottom: '1rem',
    [MEDIA_QUERY.MD]: {
      padding: '1rem 0 1rem 0',
    },
  }),
  foodGallery: css({
    maxWidth: '140rem',
    objectFit: 'contain',
    width: '90%',
  }),
  section: css({
    maxWidth: '140rem',
    objectFit: 'contain',
    width: '90%',
    paddingTop: '4rem',
    [MEDIA_QUERY.MD]: {
      padding: '1rem 0 .5rem 0',
    },
  }),
  sectionBG: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    backgroundSize: 'contain',
  }),
}

export default Promotions
