import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class ChoicesCartItem extends Component {
  render() {
    const { count, name, price, choices, totalPrice } = this.props
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.ChoicesCartItemView
            count={count}
            name={name}
            price={price}
            totalPrice={totalPrice}
            ChoicesListElement={choices.map((choice, index) => {
              return <Cart.ChoiceItemView key={index} {...choice} />
            })}
            CancelButtonElement={
              <CFButton onClick={this._handleRemoveClick}>
                <Cart.CartItemRemoveButtonView />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleRemoveClick = () => {
    const { index, name } = this.props
    dispatch.user.subtractChoicesCartItem(index)
    dispatch.notification.setMessage({
      message: `${name} removed from Cart`,
      level: 'success',
    })
  }
}
