import React from 'react'
import { Space } from 'components'
import { css } from 'emotion'

const OrderDetailsModalView = ({ OrderDetailsElement, CloseButtonElement }) => (
  <div className={styles.orderDetails}>
    {OrderDetailsElement}
    <Space h2 />
    {CloseButtonElement}
  </div>
)

const styles = {
  orderDetails: css({
    backgroundColor: 'white',
    border: '3px solid #000',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '50rem',
  }),
}

export default OrderDetailsModalView
