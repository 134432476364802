import React from 'react'
import { Space, Text } from 'components'
import { css } from 'emotion'

const OrderHistoryView = ({ OrdersListElement, OrderDetailsModalElement }) => (
  <div className={styles.orderHistory}>
    <Text heading h2 red bold>
      Order History
    </Text>
    <Space h2 />
    <div className={styles.orders}>{OrdersListElement}</div>
    {OrderDetailsModalElement}
  </div>
)

const styles = {
  orderHistory: css({
    width: '100%',
    textAlign: 'center',
  }),
  orders: css({
    height: '82vh',
    overflow: 'auto',
  }),
}

export default OrderHistoryView
