import createUserModel from './userModel'

export default (stripeKey, serverUrl) => ({
  config: {
    models: {
      user: createUserModel(stripeKey, serverUrl),
    },
  },
  onStoreCreated(store) {
    store.dispatch({ type: 'user/subscribeAuth' })
  },
})
