import { COLOR as cfColors } from 'cf-web/src/styles'
export * from 'cf-web/src/styles'

export const COLOR = {
  ...cfColors,
  primary: '#1E1E1E',
  secondary: 'blue',
  black: '#1E1E1E',
  disabled: 'grey',
  lightGrey: '#E6E6E6',
  grey: '#BBBBBB',
  mediumGrey: '#888888',
  darkGrey: '#4A4A4A',
  red: '#D0021B',
  Orange: '#FF9F00',
  Green: '#BEE100',
  darkGreen: '#0FD05A',
}
