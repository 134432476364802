import React from 'react'
import { CFView } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ rating, isChecked }) => {
  return (
    <CFView ml=".4rem">
      <FontAwesomeIcon
        icon="star"
        size="lg"
        color={isChecked ? 'red' : 'black'}
      />
    </CFView>
  )
}
