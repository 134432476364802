import React, { Component } from 'react'
import { CFModal, CFButton, PrimaryButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'
import { dispatch } from 'cf-web-app/store'
import { cloneDeep } from 'lodash'

export default class ProductModifierModal extends Component {
  state = {
    modifierIndex: 0,
    selectedModifiers: [],
  }
  defaultSelectedModifiers = []
  componentDidMount() {
    const modifiers = dispatch.restaurant.getModifiers()
    const productModifiers = dispatch.restaurant.getProductModifiers(
      this.props.productId
    )
    this.defaultSelectedModifiers = productModifiers.map(modifierId => {
      return { ...modifiers[modifierId] }
    })
    this.setState({
      selectedModifiers: cloneDeep(this.defaultSelectedModifiers),
    })
  }
  render() {
    const { selectedModifiers, modifierIndex } = this.state
    const { isOpen } = this.props
    const modifier = selectedModifiers[modifierIndex]
    return (
      <CFModal isOpen={isOpen} onRequestClose={this._handleClosePressed}>
        <ThemeContext.Consumer>
          {({ Menu }) => (
            <Menu.ProductModifierModalView
              modifier={modifier}
              ProductModifierChoicesElement={modifier.choices.map(
                (choice, index) => (
                  <CFButton
                    key={index}
                    onClick={() => this._handleChoicePressed(index)}
                  >
                    <Menu.ProductModifierChoiceView
                      modifier={modifier}
                      choice={choice}
                    />
                  </CFButton>
                )
              )}
              CloseButtonElement={
                <PrimaryButton
                  label="CLOSE"
                  onClick={this._handleClosePressed}
                />
              }
              NextButtonElement={
                <PrimaryButton
                  label={
                    modifierIndex < selectedModifiers.length - 1
                      ? 'NEXT'
                      : 'FINISH'
                  }
                  onClick={this._handleNextPressed}
                />
              }
            />
          )}
        </ThemeContext.Consumer>
      </CFModal>
    )
  }
  _handleClosePressed = () => {
    this.setState({
      modifierIndex: 0,
      selectedModifiers: cloneDeep(this.defaultSelectedModifiers),
    })
    this.props.onRequestClose()
  }
  _handleChoicePressed = choiceIndex => {
    let { selectedModifiers, modifierIndex } = this.state
    const currentModifier = selectedModifiers[modifierIndex]
    const oneChoiceRequired =
      currentModifier.required === 1 && currentModifier.maxSelect === 1
    if (oneChoiceRequired && currentModifier.choices[choiceIndex].selected) {
      return
    }
    const choices = currentModifier.choices.map((choice, index) => {
      let selected = choice.selected
      let choiceCount = currentModifier.choiceCount
      if (oneChoiceRequired && choiceIndex !== index && selected) {
        selected = false
        choiceCount--
      } else if (choiceIndex === index) {
        if (selected) {
          selected = false
          choiceCount--
        } else {
          selected = true
          choiceCount++
        }
      }
      if (
        !oneChoiceRequired &&
        currentModifier.maxSelect > 0 &&
        choiceCount > currentModifier.maxSelect
      ) {
        dispatch.notification.setMessage({
          message: 'Too many choices!',
          level: 'error',
        })
      } else {
        currentModifier.choiceCount = choiceCount
        choice.selected = selected
      }
      return choice
    })
    currentModifier.choices = choices
    selectedModifiers[modifierIndex] = currentModifier
    this.setState({
      selectedModifiers: [...selectedModifiers],
    })
  }
  _handleNextPressed = () => {
    const { modifierIndex, selectedModifiers } = this.state
    if (
      selectedModifiers[modifierIndex].choiceCount <
      selectedModifiers[modifierIndex].required
    ) {
      dispatch.notification.setMessage({
        message: 'Need to select more choices!',
        level: 'error',
      })
    } else {
      if (modifierIndex < selectedModifiers.length - 1) {
        this.setState({ modifierIndex: modifierIndex + 1 })
      } else {
        const choices = []
        const { selectedModifiers } = this.state
        let isEnoughChoices = true
        selectedModifiers.forEach(modifier => {
          const choiceCount = modifier.choiceCount
          if (choiceCount < modifier.required) {
            isEnoughChoices = false
          }
          modifier.choices.forEach(choice => {
            if (choice.selected) {
              choices.push({ name: choice.name, price: Number(choice.price) })
            }
          })
        })
        if (!isEnoughChoices) {
          dispatch.notification.setMessage({
            message: `Must pick more choices!`,
            level: 'error',
          })
          return
        }
        this.setState({
          modifierIndex: 0,
          selectedModifiers: cloneDeep(this.defaultSelectedModifiers),
        })
        this.props.addToCart(choices)
      }
    }
  }
}
