import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const ProductOptionView = ({
  isOptionSelected,
  optionNameFormatted,
  optionPriceFormatted,
  RadioButtonElement,
}) => (
  <div
    className={css(
      isOptionSelected ? styles.optionSelected : styles.option,
      styles.optionRow
    )}
  >
    <Text content h5>
      {optionNameFormatted}
    </Text>
    <div className={styles.radio}>
      <Text content h5 className={styles.price}>
        ${optionPriceFormatted}
      </Text>
      {RadioButtonElement}
    </div>
  </div>
)

const styles = {
  optionRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 1.5rem .5rem 1.5rem',
  }),
  option: css({
    color: '#9B9B9B',
  }),
  radio: css({
    display: 'flex',
    alignItems: 'center',
  }),
  optionSelected: css({
    color: 'white',
    backgroundColor: '#D0021B',
  }),
  price: css({
    marginRight: '2rem',
  }),
}

export default ProductOptionView
