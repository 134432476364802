import React from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Text } from 'components'

export default ({ address, phoneNumber, email }) => (
  <CFView column>
    {address && (
      <CFView row w="100%" mb="1rem">
        <CFView w="9rem">
          <Text heading h4 white>
            Address
          </Text>
        </CFView>
        <CFLink href="https://goo.gl/maps/brqp2HYXkENj7UzEA">
          <div className={styles.address}>
            <Text heading h4 white>
              {address}
            </Text>
          </div>
        </CFLink>
      </CFView>
    )}
    {phoneNumber && (
      <CFView row w="100%" mb="1rem">
        <CFView w="9rem">
          <Text heading h4 white>
            Phone
          </Text>
        </CFView>
        <Text heading h4 white>
          {phoneNumber}
        </Text>
      </CFView>
    )}
    {email && (
      <CFView row w="100%">
        <CFView w="9rem">
          <Text heading h4 white>
            Email
          </Text>
        </CFView>
        <Text heading h4 white>
          {email}
        </Text>
      </CFView>
    )}
  </CFView>
)

const styles = {
  address: css({
    paddingLeft: '2rem',
  }),
}
