import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const OrderHistoryRowView = ({
  orderNumber,
  createdAtFormatted,
  status,
  totalFormatted,
}) => (
  <div className={styles.orderItem}>
    <CFView column alignStart>
      <Text content h4 dark>{`#${orderNumber}`}</Text>
      <Text content h4 dark>
        {createdAtFormatted}
      </Text>
    </CFView>
    <CFView column alignEnd>
      <Text content h4 dark>
        {status}
      </Text>
      <Text content h4 dark>
        ${totalFormatted}
      </Text>
    </CFView>
  </div>
)

const styles = {
  orderItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2rem',
    margin: '.5rem 0 .5rem 0',
    backgroundColor: 'white',
    border: '.2rem solid black',
    cursor: 'pointer',
    borderRadius: '1rem',
  }),
  date: css({
    width: '65%',
    display: 'flex',
    justifyContent: 'space-between',
    [MEDIA_QUERY.SM]: {
      width: 'auto',
    },
  }),
}

export default OrderHistoryRowView
