import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class SubtractButton extends Component {
  render() {
    const { disabled, onClick } = this.props
    return (
      <CFButton disabled={disabled} onClick={onClick}>
        <ThemeContext.Consumer>
          {({ Components }) => (
            <Components.SubtractButtonView disabled={disabled} />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}
