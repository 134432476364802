import React from 'react'
import { css } from 'emotion'
import { CFView } from 'components'
import { MEDIA_QUERY } from 'styles'

const CategoriesContainerView = ({
  StoreClosedBannerElement,
  MenuSelectionElement,
  CategoriesElement,
}) => (
  <div className={styles.container}>
    <CFView column w="98%" p="0 .5rem 0 .5rem" maxWidth="42rem">
      {StoreClosedBannerElement}
      {MenuSelectionElement && (
        <CFView mb="1rem">{MenuSelectionElement}</CFView>
      )}
    </CFView>
    {CategoriesElement}
  </div>
)

const styles = {
  container: css({
    width: '44vw',
    maxWidth: '44rem',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    [MEDIA_QUERY.MD]: {
      paddingLeft: '.5rem',
      width: '90vw',
    },
  }),
}

export default CategoriesContainerView
