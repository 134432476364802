import React, { Fragment } from 'react'
import { CFView, Space } from 'components'

export default ({ GoBackButtonElement, ProductsElement }) => (
  <Fragment>
    <CFView ml="1rem" mt="1rem">
      {GoBackButtonElement}
    </CFView>
    <Space h2 />
    {ProductsElement}
    <Space h2 />
  </Fragment>
)
