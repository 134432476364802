import React from 'react'
import { css } from 'emotion'
import { CFView, CFText } from 'components'
import { MEDIA_QUERY } from 'styles'

export default ({ className, toggled, disabled, label }) => {
  const classNames = css(
    disabled ? styles.disabled : toggled ? styles.toggled : styles.button,
    className
  )
  return (
    <CFView center className={classNames}>
      <CFText tech bold className={styles.label}>
        {label}
      </CFText>
    </CFView>
  )
}

const styles = {
  button: css({
    height: '4rem',
    borderRadius: '.8rem',
    background: 'white',
    color: '#D0021B',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '.2rem .2rem .8rem rgba(0,0,0,.7)',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  toggled: css({
    height: '4rem',
    borderRadius: '.8rem',
    background: '#D0021B',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  disabled: css({
    height: '4rem',
    borderRadius: '.8rem',
    background: 'rgba(255,255,255,.3)',
    color: '#D0021B',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '.2rem .2rem .8rem rgba(0,0,0,.7)',
  }),
  label: css({
    fontSize: 18,
    [MEDIA_QUERY.SM]: {
      fontSize: 14,
    },
  }),
}
