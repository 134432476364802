import React, { Fragment, Component } from 'react'
import { CFSelect } from 'cf-web-app/components'
import CategoryItem from './CategoryItem'
import { dispatch } from 'cf-web-app/store'

export default class Categories extends Component {
  render() {
    const { menuId, onCategorySelect, selected } = this.props
    return (
      <Fragment>
        <CategoryItem
          id="recent"
          selected={selected}
          onCategorySelect={onCategorySelect}
          name="Recent Orders"
        />
        <CFSelect
          selector={{
            categories: dispatch.restaurant.getCategories,
            menu: () => dispatch.restaurant.getMenu(menuId),
          }}
        >
          {this._renderCategories}
        </CFSelect>
      </Fragment>
    )
  }
  _renderCategories = ({ categories, menu }) => {
    const { categoryOrder = [] } = menu
    const { onCategorySelect } = this.props
    const categoriesInOrder = categoryOrder.reduce((prev, categoryId) => {
      if (categories[categoryId]) {
        prev.push(categories[categoryId])
      }
      return prev
    }, [])
    return categoriesInOrder.map((category, index) => {
      const { id } = category
      return (
        <CategoryItem
          key={id}
          selected={this.props.selected}
          onCategorySelect={onCategorySelect}
          index={index}
          {...category}
        />
      )
    })
  }
}
