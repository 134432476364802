import React from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'

const TextButtonView = ({ label }) => (
  <CFView className={styles.button}>
    <Text content h4 dark>
      {label}
    </Text>
  </CFView>
)

const styles = {
  button: css({
    backgroundColor: 'transparent',
    borderBottom: '.2rem solid black',
    cursor: 'pointer',
    padding: '.1rem',
  }),
}

export default TextButtonView
