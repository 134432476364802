import React from 'react'
import { CFView } from 'components'

export default ({
  DefaultButtonElement,
  Menu1ButtonElement,
  Menu2ButtonElement,
}) => (
  <CFView row justifyCenter w="100%">
    <CFView fill>{DefaultButtonElement}</CFView>
    {Menu1ButtonElement && (
      <CFView fill ml="1rem">
        {Menu1ButtonElement}
      </CFView>
    )}
    {Menu2ButtonElement && (
      <CFView fill ml="1rem">
        {Menu2ButtonElement}
      </CFView>
    )}
  </CFView>
)
