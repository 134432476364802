import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class RewardItem extends Component {
  render() {
    const { count, name, productId, totalDiscountSubtotal } = this.props
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.RewardItemView
            count={count}
            name={name}
            productId={productId}
            totalDiscountSubtotal={totalDiscountSubtotal}
            CancelButtonElement={
              <CFButton onClick={this._handleRemoveClick}>
                <Cart.RewardItemRemoveButtonView />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleRemoveClick = () => {
    const { id, name } = this.props
    dispatch.user.removePromo(id)
    dispatch.notification.setMessage({
      message: `${name} unredeemed!`,
      level: 'success',
    })
  }
}
