import * as api from '../../api'
import { get } from 'lodash'
import { isMobile } from 'cf-core/src/config/firebase'
import { moment } from 'cf-utils'
import { TYPES } from 'cf-core/src/constants'

export default ({ dispatch, getState, stripeKey, serverUrl }) => ({
  getCustomerId() {
    return getState().user.payment.customerId
  },
  getHasPayment() {
    return !!dispatch.user.getCustomerId()
  },
  getLast4() {
    return get(getState(), 'user.payment.card.last4', '')
  },
  getExpiryDate() {
    const month = get(getState(), 'user.payment.card.exp_month', 0)
    const year = get(getState(), 'user.payment.card.exp_year', 0)
    if (month && year) {
      return ('0' + month).slice(-2) + '/' + year.toString().slice(-2)
    }
    return ''
  },
  async addCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    // Create a new card
    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year } = response.data.card

    // Create a new customer on server side
    const res = await api.server.createCustomerWithStripe(serverUrl, userToken, {
      cardToken: response.data.id,
    })

    const payment = {
      card: { last4, id, brand, exp_month, exp_year },
      customerId: res.data.id,
    }

    const userId = dispatch.user.getUserId()

    api.user.updateUser(userId, { payment })
  },
  async updateCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year } = response.data.card

    // Create a new customer on server side
    const res = await api.server.updateCustomerWithStripe(serverUrl, userToken, {
      cardToken: response.data.id,
    })

    const payment = {
      card: { last4, id, brand, exp_month, exp_year },
      customerId: res.data.id,
    }

    const userId = dispatch.user.getUserId()

    api.user.updateUser(userId, { payment })
  },
  async createOrder() {
    if (!dispatch.user.getIsLoggedIn()) {
      throw new Error('Not Authenticated')
    }
    if (!dispatch.restaurant.getIsStoreOpen()) {
      alert('Sorry Ordering is currently closed')
      return
    }

    dispatch.user.setIsPlacingOrder(true)
    try {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const products = dispatch.user.getCart()
      const choicesProducts = dispatch.user.getChoicesCart()
      const tip = dispatch.user.getTip()
      const tableId = dispatch.user.getTableId()
      let orderType = dispatch.user.getOrderType()
      if (!orderType) {
        orderType = 'Pickup'
      }
      const notes = dispatch.user.getNotes()
      const validPromosWithDetails = dispatch.user.getValidPromosWithDetails()
      // Remove everything from promos except id and count before sending to server
      const promos = Object.keys(validPromosWithDetails).reduce((prev, promoId) => {
        prev[promoId] = {
          id: promoId,
          count: validPromosWithDetails[promoId].count,
        }
        return prev
      }, {})
      const userToken = await dispatch.user.getUserToken()
      const userId = dispatch.user.getUserId()
      const {
        data: {
          order: { orderNumber, tableNumber, pointsEarned, orderId, total },
        },
      } = await api.server.createOrderAndCharge(serverUrl, userToken, restaurantId, locationId, {
        products,
        choicesProducts,
        notes,
        tip,
        promos,
        orderType,
        tableId,
        sourceClient: isMobile ? 'mobile' : 'web',
      })
      dispatch({
        type: TYPES.ANALYTICS.ORDER_COMPLETE,
        userId,
        orderId,
        total,
        pointsEarned,
        orderNumber,
        restaurantId,
        locationId,
        notes,
      })
      api.user.updateUserRestaurant(userId, restaurantId, {
        reviewOrderId: orderId,
      })
      dispatch.user._clearNotes()
      const completionTime =
        orderType === 'Delivery'
          ? moment().add(dispatch.restaurant.getWaitTime() + dispatch.restaurant.getDeliveryTime(), 'minutes')
          : moment().add(dispatch.restaurant.getWaitTime(), 'minutes')
      const orderDetails = {
        orderId,
        orderNumber,
        orderType,
        tableNumber,
        completionTime,
        products,
        pointsEarned,
        promos,
        total,
      }

      return orderDetails
    } catch (error) {
      dispatch({ type: TYPES.ANALYTICS.ORDER_FAILED, error })
      throw error
    } finally {
      dispatch.user.setIsPlacingOrder(false)
    }
  },
})
