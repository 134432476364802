import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

class Logo extends Component {
  render() {
    return (
      <CFButton onClick={() => this.props.history.push('/')}>
        <ThemeContext.Consumer>
          {({ Components }) => <Components.LogoView />}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}

export default withRouter(Logo)
