import React, { Component } from 'react'
import { CFSelect } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class StoreClosedBanner extends Component {
  componentDidMount() {
    this.internval = setInterval(() => {
      this.forceUpdate()
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.internval)
  }
  render() {
    return (
      <CFSelect selectorNot={dispatch.restaurant.getIsStoreOpen}>
        <ThemeContext.Consumer>
          {({ Components }) => <Components.StoreClosedBannerView />}
        </ThemeContext.Consumer>
      </CFSelect>
    )
  }
}
