import React, { Component } from 'react'
import { css } from 'emotion'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

class CFModal extends Component {
  render() {
    const { children, isOpen, closeModal } = this.props
    return (
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: 0,
            backgroundColor: 'transparent',
            transform: 'translate(-50%, -50%)',
          },
        }}
        contentLabel="Modal"
        overlayClassName={styles.overlay}
        bodyOpenClassName={styles.body}
      >
        <div className={styles.container}>{children}</div>
      </ReactModal>
    )
  }
}

const styles = {
  container: css({
    overflowY: 'auto',
    maxHeight: '90vh',
  }),
  overlay: css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .3)',
    zIndex: 99,
  }),
  body: css({
    overflowY: 'hidden',
  }),
}

export default CFModal
