export * from 'cf-core/src/images'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1570765843/hokepoke/cflogo.png'
export const gallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1570765847/hokepoke/gallery.png'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/logoTitle.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1662659132/typhoonbbt/newLogo.png'
export const orderButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/orderButton.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/downloadButton.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528722/typhoonbbt/hero.jpg'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651282983/typhoonbbt/Promotions2.png'
export const specialPromo =
  'https://dm2nllqmoztmf.cloudfront.net/specialPromo.webp'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651282983/typhoonbbt/about2.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576556824/typhoonbbt/foodGallery.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528718/typhoonbbt/homebg.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/contactbg.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1662659132/typhoonbbt/locationMap2.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/rewardbg.jpg'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/redeemButton.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/rewardArrowRight.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/pointsMeter.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1575528530/typhoonbbt/rewardDefault.png'
