import React from 'react'
import { CFView, CFText } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

export default ({
  Tip1ButtonElement,
  Tip2ButtonElement,
  Tip3ButtonElement,
  NoTipButtonElement,
}) => (
  <CFView className={styles.container}>
    <CFText h4 black>
      Tip:
    </CFText>
    {Tip1ButtonElement}
    {Tip2ButtonElement}
    {Tip3ButtonElement}
    {NoTipButtonElement}
  </CFView>
)

const styles = {
  container: css({
    display: 'flex',
    width: '35rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      width: '100%',
    },
  }),
}
