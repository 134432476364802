import React from 'react'
import { CFView, CFText } from 'components'

export default ({ name, price }) => (
  <CFView ml="2rem">
    <CFText h6 black bold>
      • {name} {price > 0 && `($${price.toFixed(2)})`}
    </CFText>
  </CFView>
)
