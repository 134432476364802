import React, { Component } from 'react'
import { css } from 'emotion'
import { CFSelect, ToggleButtonView } from 'components'
import { dispatch } from 'cf-web-app/store'

export default class DeliveryButtonView extends Component {
  componentDidMount() {
    this.internval = setInterval(() => {
      this.forceUpdate()
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.internval)
  }
  render() {
    const { disabled, ...rest } = this.props
    return (
      <CFSelect selector={dispatch.restaurant.getIsDeliveryHoursOpen}>
        {isDeliveryOpen => (
          <ToggleButtonView
            {...rest}
            disabled={!isDeliveryOpen || disabled}
            label="Delivery"
            className={styles.deliveryButton}
          />
        )}
      </CFSelect>
    )
  }
}

const styles = {
  deliveryButton: css({
    width: '12rem',
  }),
}
