import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class Page404 extends Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Page404 }) => (
          <Page404.Page404View
            MenuButtonElement={
              <CFButton onClick={() => this.props.history.push('/menu')}>
                <Page404.MenuButtonView />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
}
