import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'styles'

const CheckButtonView = () => (
  <FontAwesomeIcon icon="check-circle" className={styles.button} />
)

const styles = {
  button: css({
    color: COLOR.red,
    cursor: 'pointer',
    fontSize: '2.1rem',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}

export default CheckButtonView
