import React, { Component } from 'react'
import { CFFBSubscribe } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class OrderStatus extends Component {
  orderDoc = dispatch.user.getOrderDoc({ orderId: this.props.orderId })
  render() {
    return (
      <CFFBSubscribe doc={this.orderDoc} onError={this._onError}>
        {({ loading, data: orderDetails }) => (
          <ThemeContext.Consumer>
            {({ Components }) => (
              <Components.OrderStatusView
                loading={loading}
                status={!loading && orderDetails.status}
              />
            )}
          </ThemeContext.Consumer>
        )}
      </CFFBSubscribe>
    )
  }
  _onError = error => {
    console.warn(error)
    alert(error.message)
  }
}
