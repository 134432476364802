import React, { Component } from 'react'
import { css } from 'emotion'

class CFLink extends Component {
  render() {
    const { className, children, disabled, ...props } = this.props
    const classNames = css(styles.link, className)
    return (
      <a
        className={classNames}
        disabled={disabled}
        style={disabled ? { opacity: 0.5 } : {}}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    )
  }
}

const styles = {
  link: css({
    textDecoration: 'none',
  }),
}

export default CFLink
