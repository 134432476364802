import React from 'react'
import { css } from 'emotion'
import { orderStatusProgress, orderStatusMeter } from 'images'
import { MediaQuery } from 'components'
import { MEDIA_QUERY } from 'styles'

const OrderStatusView = ({ loading, status }) => {
  let statusMeterWidth = 0
  switch (status) {
    case 'New':
      statusMeterWidth = 0.263
      break
    case 'Preparing':
      statusMeterWidth = 0.621
      break
    case 'Done':
      statusMeterWidth = 1
      break
    default:
      break
  }
  return (
    <div className={styles.container}>
      <div className={styles.meter}>
        <MediaQuery maxWidth={576}>
          {matches =>
            matches ? (
              <div
                className={styles.progress}
                style={{
                  width: `${statusMeterWidth * 28}rem`,
                  transition: 'width 2s',
                }}
              >
                <img
                  src={orderStatusProgress}
                  className={styles.fill}
                  alt="Progress"
                />
              </div>
            ) : (
              <div
                className={styles.progress}
                style={{
                  width: `${statusMeterWidth * 40}rem`,
                  transition: 'width 2s',
                }}
              >
                <img
                  src={orderStatusProgress}
                  className={styles.fill}
                  alt="Progress"
                />
              </div>
            )
          }
        </MediaQuery>
        <img src={orderStatusMeter} className={styles.meter} alt="Meter" />
      </div>
    </div>
  )
}

const styles = {
  container: css({
    height: '12rem',
    width: '40rem',
    position: 'relative',
    [MEDIA_QUERY.SM]: {
      width: '28rem',
      height: '8rem',
    },
  }),
  meter: css({
    width: '40rem',
    position: 'absolute',
    objectFit: 'contain',
    [MEDIA_QUERY.SM]: {
      width: '28rem',
    },
  }),
  progress: css({
    position: 'absolute',
    overflow: 'hidden',
  }),
  fill: css({
    width: '40rem',
    objectFit: 'contain',
    [MEDIA_QUERY.SM]: {
      width: '28rem',
    },
  }),
}

export default OrderStatusView
