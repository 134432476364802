import React, { Component } from 'react'
import { CFButton, CFSelect, StoreClosedBanner } from 'cf-web-app/components'
import Categories from './Categories'
import Products from './Products'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class Menu extends Component {
  state = {
    menuId: 'default',
    categoryId: '',
    showMenu: 'categories',
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <Menu.MenuView
            MenuContentElement={
              <CFSelect
                selector={{
                  loading: dispatch.restaurant.getLoading,
                }}
              >
                {this.renderMenu}
              </CFSelect>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  renderMenu = ({ loading }) => {
    const categoryId = this.state.categoryId
      ? this.state.categoryId
      : dispatch.restaurant.getCategoryOrder('default')[0]
    return loading ? (
      <ThemeContext.Consumer>
        {({ Menu }) => <Menu.LoadingView />}
      </ThemeContext.Consumer>
    ) : (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <Menu.CategoriesTopContainerView
            CategoryListMDElement={
              this.state.showMenu === 'products'
                ? null
                : this._renderCategories({ isMDScreen: true, categoryId })
            }
            CategoryListElement={this._renderCategories({
              isMDScreen: false,
              categoryId,
            })}
            ProductListMDElement={
              this.state.showMenu === 'categories'
                ? null
                : this._renderProducts({ isMDScreen: true, categoryId })
            }
            ProductListElement={this._renderProducts({
              isMDScreen: false,
              categoryId,
            })}
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _renderCategories = ({ isMDScreen, categoryId }) => {
    return (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <Menu.CategoriesContainerView
            StoreClosedBannerElement={<StoreClosedBanner />}
            MenuSelectionElement={
              dispatch.restaurant.getActiveMenuCount() > 1 ? (
                <Menu.MenuSelectionView
                  DefaultButtonElement={
                    <CFButton
                      style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          menuId: 'default',
                          categoryId: dispatch.restaurant.getCategoryOrder(
                            'default'
                          )[0],
                        })
                      }
                    >
                      <Menu.MenuButtonView
                        selected={this.state.menuId === 'default'}
                        name={
                          <CFSelect
                            selector={() =>
                              dispatch.restaurant.getMenu('default').name
                            }
                          />
                        }
                      />
                    </CFButton>
                  }
                  Menu1ButtonElement={
                    dispatch.restaurant.getIsMenuActive('menu1') ? (
                      <CFButton
                        style={{ width: '100%' }}
                        onClick={() =>
                          this.setState({
                            menuId: 'menu1',
                            categoryId: dispatch.restaurant.getCategoryOrder(
                              'menu1'
                            )[0],
                          })
                        }
                      >
                        <Menu.MenuButtonView
                          selected={this.state.menuId === 'menu1'}
                          name={
                            <CFSelect
                              selector={() =>
                                dispatch.restaurant.getMenu('menu1').name
                              }
                            />
                          }
                        />
                      </CFButton>
                    ) : null
                  }
                  Menu2ButtonElement={
                    dispatch.restaurant.getIsMenuActive('menu2') ? (
                      <CFButton
                        style={{ width: '100%' }}
                        onClick={() =>
                          this.setState({
                            menuId: 'menu2',
                            categoryId: dispatch.restaurant.getCategoryOrder(
                              'menu2'
                            )[0],
                          })
                        }
                      >
                        <Menu.MenuButtonView
                          selected={this.state.menuId === 'menu2'}
                          name={
                            <CFSelect
                              selector={() =>
                                dispatch.restaurant.getMenu('menu2').name
                              }
                            />
                          }
                        />
                      </CFButton>
                    ) : null
                  }
                />
              ) : null
            }
            CategoriesElement={
              <Categories
                menuId={this.state.menuId}
                onCategorySelect={this._handleCategorySelect}
                selected={isMDScreen ? '' : categoryId}
              />
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _renderProducts = ({ isMDScreen, categoryId }) => {
    return isMDScreen ? (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <Menu.ProductsContainerView
            GoBackButtonElement={
              <CFButton onClick={this._handleGoBack}>
                <Menu.ProductsGoBackButtonView />
              </CFButton>
            }
            ProductsElement={
              <Products menuId={this.state.menuId} categoryId={categoryId} />
            }
          />
        )}
      </ThemeContext.Consumer>
    ) : (
      <Products menuId={this.state.menuId} categoryId={categoryId} />
    )
  }
  _handleCategorySelect = categoryId => {
    this.setState({ categoryId, showMenu: 'products' })
  }
  _handleGoBack = () => {
    this.setState({ categoryId: '', showMenu: 'categories' })
  }
}
