export default {
  RESTAURANT_ID: process.env.REACT_APP_RESTAURANT_ID,
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
}

// const ENV = {
//   dev: {
//     RESTAURANT_ID: '39nCpPX9RUrUufu7REh1_test',
//     SERVER_URL: 'https://coldfusion-server-staging.herokuapp.com',
//     STRIPE_KEY: 'pk_test_Fpwbb04U5VsU9kxtDaFnoOXc',
//   },
//   local: {
//     RESTAURANT_ID: '39nCpPX9RUrUufu7REh1_test',
//     SERVER_URL: 'http://localhost:3002',
//     STRIPE_KEY: 'pk_test_Fpwbb04U5VsU9kxtDaFnoOXc',
//   },
//   staging: {
//     RESTAURANT_ID: '39nCpPX9RUrUufu7REh1_test',
//     SERVER_URL: 'https://coldfusion-server-staging.herokuapp.com',
//     STRIPE_KEY: 'pk_test_Fpwbb04U5VsU9kxtDaFnoOXc',
//   },
//   production: {
//     RESTAURANT_ID: '39nCpPX9RUrUufu7REh1',
//     SERVER_URL: 'https://coldfusion-server.herokuapp.com',
//     STRIPE_KEY: 'pk_live_nScNTL1HZsjWTPga0xJrnLEy',
//   },
// }

// const envSetting = process.env.REACT_APP_ENVIRONMENT
// export default ENV[envSetting]
