export { default as coldfusion } from './coldfusion.png'
export const codeFusionLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1549536525/codefusionlogo.png'
export const codeFusionVideo = {
  webm: {
    src: 'https://res.cloudinary.com/codefusiontech/video/upload/vc_vp9/v1549536154/codefusionvideo.webm',
    type: 'video/webm',
    codecs: 'vp9',
  },
  hevc: {
    src: 'https://res.cloudinary.com/codefusiontech/video/upload/vc_h265/v1549536154/codefusionvideo.mp4',
    type: 'video/mp4',
    codecs: 'hevc',
  },
  mp4: {
    src: 'https://res.cloudinary.com/codefusiontech/video/upload/vc_auto/v1549536154/codefusionvideo.mp4',
    type: 'video/mp4',
    codecs: 'video/mp4',
  },
}
