export { default as PaymentView } from './PaymentView'
export { default as SubmitButtonView } from './SubmitButtonView'

export const cardNumberInputProps = {
  placeholder: 'Card Number',
}

export const expiryInputProps = {
  placeholder: 'Expiry',
}

export const CVCInputProps = {
  placeholder: 'CVC',
}
