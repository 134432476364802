import React from 'react'
import { css } from 'emotion'

const RewardsContainerView = ({ RewardsElement }) => (
  <div className={styles.container}>{RewardsElement}</div>
)

const styles = {
  container: css({
    height: '60.2rem',
    width: '37.5rem',
  }),
}

export default RewardsContainerView
