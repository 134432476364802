import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  CFButton,
  CFSelect,
  CFInput,
  CFRenderless,
} from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { isEqual, isEmpty } from 'lodash'
import { ThemeContext } from 'cf-web-app'
import ForgotPasswordButton from './ForgotPasswordButton'

const STATES = {
  ENTER_EMAIL: {
    verifyingEmail: false,
    emailExistConfirmed: false,
    emailDontExistConfirmed: false,
  },
  CHECKING_EMAIL: {
    verifyingEmail: true,
    emailExistConfirmed: false,
    emailDontExistConfirmed: false,
  },
  EMAIL_LOCKED_ENTER_PASS: {
    verifyingEmail: false,
    emailExistConfirmed: true,
    emailDontExistConfirmed: false,
  },
  REGISTER_ENTER_EMAIL_AND_PASS: {
    verifyingEmail: false,
    emailExistConfirmed: false,
    emailDontExistConfirmed: true,
  },
}

class Auth extends Component {
  state = STATES.ENTER_EMAIL
  emailInput = React.createRef()
  passInput = React.createRef()
  nameInput = React.createRef()
  phoneInput = React.createRef()
  render() {
    return (
      <>
        <CFSelect selector={dispatch.user.getIsLoggedIn}>
          <CFRenderless
            componentDidMount={() => {
              this._handleNextRoute()
            }}
          />
        </CFSelect>
        <ThemeContext.Consumer>
          {({ Auth }) => (
            <Auth.AuthView
              GoBackButtonElement={
                (this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) ||
                  this._isState(STATES.EMAIL_LOCKED_ENTER_PASS)) && (
                  <CFButton onClick={() => this.setState(STATES.ENTER_EMAIL)}>
                    <Auth.GoBackButtonView />
                  </CFButton>
                )
              }
              EmailInputElement={
                <CFInput
                  disabled={!this._isState(STATES.ENTER_EMAIL)}
                  ref={this.emailInput}
                  onKeyPress={this._handleKeyPress}
                  {...Auth.emailInputProps}
                />
              }
              PasswordInputElement={
                (this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) ||
                  this._isState(STATES.EMAIL_LOCKED_ENTER_PASS)) && (
                  <CFInput
                    disabled={this._isState(STATES.CHECKING_EMAIL)}
                    type="password"
                    ref={this.passInput}
                    onKeyPress={this._handleKeyPress}
                    {...Auth.passwordInputProps}
                  />
                )
              }
              NameInputElement={
                this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) && (
                  <CFInput
                    ref={this.nameInput}
                    maxLength="18"
                    onKeyPress={this._handleKeyPress}
                    {...Auth.nameInputProps}
                  />
                )
              }
              PhoneInputElement={
                this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) && (
                  <CFInput
                    ref={this.phoneInput}
                    maxLength="10"
                    onKeyPress={this._handleKeyPress}
                    {...Auth.phoneInputProps}
                  />
                )
              }
              NextButtonElement={
                (this._isState(STATES.ENTER_EMAIL) ||
                  this._isState(STATES.CHECKING_EMAIL)) && (
                  <CFButton
                    disabled={this._isState(STATES.CHECKING_EMAIL)}
                    onClick={this._handleEmailEnter}
                  >
                    <Auth.NextButtonView
                      isCheckingEmail={this._isState(STATES.CHECKING_EMAIL)}
                    />
                  </CFButton>
                )
              }
              LoginButtonElement={
                (this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) ||
                  this._isState(STATES.EMAIL_LOCKED_ENTER_PASS)) && (
                  <CFSelect selector={dispatch.user.getIsLoggingIn}>
                    {isLoggingIn => {
                      if (this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS)) {
                        return (
                          <CFButton
                            disabled={isLoggingIn}
                            onClick={this._handleSignUp}
                          >
                            <Auth.SignupButtonView isLoggingIn={isLoggingIn} />
                          </CFButton>
                        )
                      } else if (
                        this._isState(STATES.EMAIL_LOCKED_ENTER_PASS)
                      ) {
                        return (
                          <CFButton
                            disabled={isLoggingIn}
                            onClick={this._handleLogin}
                          >
                            <Auth.LoginButtonView isLoggingIn={isLoggingIn} />
                          </CFButton>
                        )
                      }
                    }}
                  </CFSelect>
                )
              }
              ForgotPasswordButtonElement={
                !this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS) && (
                  <ForgotPasswordButton />
                )
              }
            />
          )}
        </ThemeContext.Consumer>
      </>
    )
  }
  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (this._isState(STATES.ENTER_EMAIL)) {
        this._handleEmailEnter(e)
      } else if (this._isState(STATES.EMAIL_LOCKED_ENTER_PASS)) {
        this._handleLogin(e)
      } else if (this._isState(STATES.REGISTER_ENTER_EMAIL_AND_PASS)) {
        this._handleSignUp(e)
      }
    }
  }
  _handleEmailEnter = e => {
    e.preventDefault()
    this.setState(STATES.CHECKING_EMAIL)
    dispatch.user
      .getIsEmailExists(this.emailInput.current.value)
      .then(res => {
        if (res) {
          this.setState(STATES.EMAIL_LOCKED_ENTER_PASS)
        } else {
          this.setState(STATES.REGISTER_ENTER_EMAIL_AND_PASS)
        }
        this.passInput.current.focus()
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
        this.setState(STATES.ENTER_EMAIL)
      })
  }
  _handleLogin = e => {
    e.preventDefault()
    const email = this.emailInput.current.value
    const password = this.passInput.current.value
    dispatch.user
      .signInWithEmailAndPassword({
        email,
        password,
      })
      .then(res => {
        this._handleSetAddressAndCart()
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
  }
  _handleSignUp = e => {
    e.preventDefault()
    const email = this.emailInput.current.value
    const password = this.passInput.current.value
    const name = this.nameInput.current.value
    const phoneNumber = this.phoneInput.current.value
    dispatch.user
      .createUserWithEmailAndPassword({
        email,
        password,
        name,
        phoneNumber,
      })
      .then(res => {
        this._handleSetAddressAndCart()
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
  }
  _handleSetAddressAndCart = () => {
    const email = dispatch.user.getEmail()
    dispatch.notification.setMessage({
      message: `Welcome! ${email}`,
      level: 'success',
    })
    const address = dispatch.user.getAddress()
    const aptNumber = dispatch.user.getAptNumber()
    const deliveryInstructions = dispatch.user.getDeliveryInstructions()
    const latLng = dispatch.user.getLatLng()
    const cart = dispatch.user.getCart()
    const choicesCart = dispatch.user.getChoicesCart()
    const orderType = dispatch.user.getOrderType()
    if (address) {
      dispatch.user.setAddress({
        address,
        aptNumber,
        deliveryInstructions,
        latLng,
      })
    }
    if (!isEmpty(cart)) {
      dispatch.user.setCart(cart)
    }
    if (!isEmpty(choicesCart)) {
      dispatch.user.setChoicesCart(choicesCart)
    }
    if (orderType) {
      dispatch.user.setOrderType(orderType)
    }
  }
  _handleNextRoute = () => {
    const { history, match } = this.props
    const { nextRoute } = match.params
    history.push(`/${nextRoute}`)
  }
  _isState(state) {
    return isEqual(this.state, state)
  }
}

export default withRouter(Auth)
