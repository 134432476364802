import React, { Component } from 'react'
import { css } from 'emotion'
import { CFText } from 'components'

class Text extends Component {
  render() {
    const {
      children,
      style: customStyle,
      className: customClassName,
      ...props
    } = this.props

    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }

      switch (key) {
        case 'color':
          style.push(css({ color: props[key] }))
          break
        default:
          break
      }
    }

    // 3. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    return (
      <CFText className={css(style)} {...props}>
        {children}
      </CFText>
    )
  }
}

const styles = {
  baseStyle: css({}),
  dafoe: css({
    fontFamily: 'dafoe',
  }),
  painting: css({
    fontFamily: 'painting',
  }),
}

export default Text
