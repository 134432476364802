import React from 'react'
import { CFButton, CFSelect, Logo } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'
import { dispatch } from 'cf-web-app/store'

export default class Home extends React.Component {
  componentDidMount() {
    const { locationId } = this.props.match.params
    if (locationId) {
      dispatch.restaurant.setLocationId(locationId)
    }
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Home }) => {
          return (
            <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
              {locationId => (
                <Home.HomeView
                  locationId={locationId}
                  phoneNumber={
                    <CFSelect
                      selector={dispatch.restaurant.getFormattedPhoneNumber}
                    />
                  }
                  HoursElement={
                    <CFSelect selector={dispatch.restaurant.getHours}>
                      {hours => <Home.HoursView hours={hours} />}
                    </CFSelect>
                  }
                  LogoElement={<Logo />}
                  ContactUsElement={
                    <CFSelect
                      selector={[
                        dispatch.restaurant.getAddress,
                        dispatch.restaurant.getFormattedPhoneNumber,
                        dispatch.restaurant.getEmail,
                      ]}
                    >
                      {([address, phoneNumber, email]) => (
                        <Home.ContactUsView
                          address={address}
                          phoneNumber={phoneNumber}
                          email={email}
                        />
                      )}
                    </CFSelect>
                  }
                  OrderNowButtonElement={
                    <CFButton onClick={this._handleOrderClick}>
                      <Home.OrderNowButtonView />
                    </CFButton>
                  }
                />
              )}
            </CFSelect>
          )
        }}
      </ThemeContext.Consumer>
    )
  }
  _handleOrderClick = () => {
    this.props.history.push('/menu')
  }
}
