import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'styles'

export default ({ selected }) =>
  selected ? (
    <FontAwesomeIcon icon="check-square" className={styles.selectedButton} />
  ) : (
    <FontAwesomeIcon icon="square" className={styles.button} />
  )

const styles = {
  button: css({
    color: COLOR.black,
    cursor: 'pointer',
    fontSize: '3rem',
  }),
  selectedButton: css({
    color: COLOR.red,
    cursor: 'pointer',
    fontSize: '3rem',
  }),
}
