import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'

export default ({ count, name, priceFormatted, ChoicesListElement }) => (
  <CFView column>
    <div className={styles.productItem}>
      <Text content black h4 className={styles.count}>
        {count}
      </Text>
      <Text content h4 black className={styles.name}>
        {name}
      </Text>
      <Text content h4 black>{` $${priceFormatted}`}</Text>
    </div>
    {ChoicesListElement}
  </CFView>
)

const styles = {
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  count: css({
    minWidth: '3rem',
  }),
  name: css({
    flex: 1,
  }),
}
