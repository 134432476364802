import React from 'react'
import { CFView, CFText } from 'components'

export default ({ name, price }) => (
  <CFView ml="3rem">
    <CFText h6 bold black>
      • {name} (${price.toFixed(2)})
    </CFText>
  </CFView>
)
