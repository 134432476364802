import React from 'react'
import { CFView, Space, Text } from 'components'
import { css } from 'emotion'

const CartItemView = ({
  count,
  name,
  totalPrice,
  ChoicesListElement,
  CancelButtonElement,
}) => (
  <div className={styles.container}>
    <CFView row justifyBetween alignCenter>
      <Text content h4 black className={styles.count}>
        {count}
      </Text>
      <Text content h4 black className={styles.name}>
        {name}
      </Text>
      <Text content h4 black>{`$${(count * totalPrice).toFixed(2)}`}</Text>
      <Space w1 />
      {CancelButtonElement}
    </CFView>
    {ChoicesListElement}
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  count: css({
    minWidth: '2rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default CartItemView
