import React from 'react'
import { css } from 'emotion'
import {
  CancelButtonView,
  ToggleButtonView,
  PrimaryButtonView,
} from 'components'
import { MEDIA_QUERY } from 'styles'

const styles = {
  deliveryButton: css({
    width: '12rem',
  }),
  tipButton: css({
    width: '6rem',
    [MEDIA_QUERY.SM]: {
      width: '5rem',
    },
  }),
}

export { default as CartView } from './CartView'
export { default as ReceiptView } from './ReceiptView'
export { default as UserDetailsModalView } from './UserDetailsModalView'
export const AddPaymentButtonView = props => (
  <PrimaryButtonView {...props} label="ADD PAYMENT" />
)
export const FinishOrderButtonView = props => (
  <PrimaryButtonView {...props} label="FINISH" />
)
export const LoginButtonView = props => (
  <PrimaryButtonView {...props} label="LOGIN" />
)
export const PlaceOrderButtonView = ({ disabled, isPlacingOrder, last4 }) => (
  <PrimaryButtonView
    disabled={disabled}
    label={isPlacingOrder ? 'PROCESSING...' : 'PAY WITH ' + last4}
  />
)
export const PlaceFreeOrderButtonView = ({ isPlacingOrder, disabled }) => (
  <PrimaryButtonView
    disabled={disabled}
    label={isPlacingOrder ? 'PROCESSING...' : 'FREE ORDER'}
  />
)

// Rewards
export { default as RewardsContainerView } from './Rewards/RewardsContainerView'
export { default as RewardsLoadedView } from './Rewards/RewardsLoadedView'
export {
  default as RewardsLeftArrowButtonView,
} from './Rewards/RewardsLeftArrowButtonView'
export {
  default as RewardsRightArrowButtonView,
} from './Rewards/RewardsRightArrowButtonView'
export {
  default as RewardsRedeemButtonView,
} from './Rewards/RewardsRedeemButtonView'

// Delivery
export { default as DeliveryView } from './Delivery/DeliveryView'
export { default as TipView } from './Delivery/TipView'
export {
  default as UserAddressModalView,
} from './Delivery/UserAddressModalView'
export { default as TimeAndPlaceView } from './Delivery/TimeAndPlaceView'
export { default as DeliveryButtonView } from './Delivery/DeliveryButtonView'
export const PickupButtonView = props => (
  <ToggleButtonView
    {...props}
    label="Pickup"
    className={styles.deliveryButton}
  />
)
export const Tip1ButtonView = props => (
  <ToggleButtonView {...props} label="10%" className={styles.tipButton} />
)
export const Tip2ButtonView = props => (
  <ToggleButtonView {...props} label="15%" className={styles.tipButton} />
)
export const Tip3ButtonView = props => (
  <ToggleButtonView {...props} label="20%" className={styles.tipButton} />
)
export const NoTipButtonView = props => (
  <ToggleButtonView {...props} label="None" className={styles.tipButton} />
)

// Summary
export { default as SummaryContainerView } from './Summary/SummaryContainerView'
export { default as SummaryView } from './Summary/SummaryView'
export { default as CartItemView } from './Summary/CartItemView'
export { default as ChoicesCartItemView } from './Summary/ChoicesCartItemView'
export { default as ChoiceItemView } from './Summary/ChoiceItemView'
export { default as RewardItemView } from './Summary/RewardItemView'
export { default as SummaryTotalView } from './Summary/SummaryTotalView'
export const CartItemRemoveButtonView = CancelButtonView
export const RewardItemRemoveButtonView = CancelButtonView

export const notesInputProps = {
  placeholder: 'Enter Special Requests \nEx. Remove cucumber from BC Roll',
  maxLength: '128',
}

export const addressInputProps = {
  placeholder: 'Enter Address',
}

export const aptNumberInputProps = {
  placeholder: 'Optional',
}

export const deliveryInstructionsInputProps = {
  placeholder: 'Enter Buzzer, etc (Optional)',
}

export const nameInputProps = {
  placeholder: 'Enter Name',
}

export const phoneInputProps = {
  placeholder: 'Enter Phone Number',
}
