import React from 'react'
import { PlaceholderView } from 'cf-web-app/components'
import MediaQuery from 'react-responsive'

const defaultValue = {
  Components: {
    LogoView: () => <PlaceholderView w={60} h={60} name="LogoView" />,
    NavbarView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="NavbarView"
      />
    ),
    PageHeader: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="PageHeader"
      />
    ),
    LocationSelectNameView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="LocationSelectNameView"
      />
    ),
    LocationSelectModalView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="LocationSelectModalView"
      />
    ),
    LocationSelectButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="LocationSelectButtonView"
      />
    ),
    Container: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="Container"
      />
    ),
    AddButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="AddButtonView"
      />
    ),
    CancelButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CancelButtonView"
      />
    ),
    CheckButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CheckButtonView"
      />
    ),
    EditButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="EditButtonView"
      />
    ),
    OrderButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderButtonView"
      />
    ),
    PrimaryButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="PrimaryButtonView"
      />
    ),
    SubtractButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="SubtractButtonView"
      />
    ),
    TextButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="TextButtonView"
      />
    ),

    Card: () => (
      <PlaceholderView style={{ minHeight: 50, minWidth: 50 }} name="Card" />
    ),

    StoreClosedBannerView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="StoreClosedBannerView"
      />
    ),
    RadioButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="RadioButtonView"
      />
    ),
  },
  Home: {
    HomeView: () => (
      <PlaceholderView
        style={{ minHeight: 1000, width: '100%' }}
        name="HomeView"
      />
    ),
    HoursView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="HoursView"
      />
    ),
    OrderNowButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderNowButtonView"
      />
    ),
  },
  Menu: {
    MenuView: ({ MenuContentElement }) => (
      <PlaceholderView
        style={{ minHeight: 1000, width: '100%' }}
        name="MenuView"
      >
        {MenuContentElement}
      </PlaceholderView>
    ),
    LoadingView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="LoadingView"
      />
    ),
    CategoriesTopContainerView: ({
      CategoryListElement,
      CategoryListMDElement,
      ProductListElement,
      ProductListMDElement,
    }) => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CategoriesTopContainerView"
      >
        <MediaQuery maxWidth={767}>
          {matches => {
            if (matches) {
              return CategoryListMDElement
            }
            return CategoryListElement
          }}
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {matches => {
            if (matches) {
              return ProductListMDElement
            }
            return ProductListElement
          }}
        </MediaQuery>
      </PlaceholderView>
    ),
    CategoriesContainerView: ({
      StoreClosedBannerElement,
      CategoriesElement,
    }) => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CategoriesContainerView"
      >
        {StoreClosedBannerElement}
        {CategoriesElement}
      </PlaceholderView>
    ),
    CategoryItemView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CategoryItemView"
      />
    ),
    ProductsContainerView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductsContainerView"
      />
    ),
    ProductsGoBackButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductsGoBackButtonView"
      />
    ),
    EmptyRecentOrderView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="EmptyRecentOrderView"
      />
    ),
    ProductsItemContainerView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductsItemContainerView"
      />
    ),
    ProductItemView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductItemView"
      />
    ),
    ProductOptionView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductOptionView"
      />
    ),
    CloseProductDetailsButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="CloseProductDetailsButtonView"
      />
    ),
    OpenProductDetailsButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OpenProductDetailsButtonView"
      />
    ),
    SubtractQtyButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="SubtractQtyButtonView"
      />
    ),
    IncrementQtyButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="IncrementQtyButtonView"
      />
    ),
    AddToCartButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="AddToCartButtonView"
      />
    ),
  },
  Page404: {
    Page404View: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="Page404View"
      />
    ),
    MenuButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="MenuButtonView"
      />
    ),
    goToMenuButtonProps: { label: 'Go to Menu' },
  },
  Payment: {
    PaymentView: () => (
      <PlaceholderView
        style={{ minHeight: 1000, width: '100%' }}
        name="PaymentView"
      />
    ),
    SubmitButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="SubmitButtonView"
      />
    ),
    cardNumberInputProps: {
      placeholder: 'Card Number',
    },
    expiryInputProps: {
      placeholder: 'Expiry',
    },
    CVCInputProps: {
      placeholder: 'CVC',
    },
  },
  User: {
    UserView: () => (
      <PlaceholderView
        style={{ minHeight: 1000, width: '100%' }}
        name="UserView"
      />
    ),
    UserLoadingView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="UserLoadingView"
      />
    ),
    UserLoadedView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="UserLoadedView"
      />
    ),
    UserDetailsView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="UserDetailsView"
      />
    ),
    UserDetailsPaymentView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="UserDetailsPaymentView"
      />
    ),
    PhoneNumberTextView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="PhoneNumberTextView"
      />
    ),
    OrderHistoryView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderHistoryView"
      />
    ),
    OrderHistorySpinnerView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderHistorySpinnerView"
      />
    ),
    OrderDetailsModalView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderDetailsModalView"
      />
    ),
    OrderDetailsView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderDetailsView"
      />
    ),
    ProductRowView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="ProductRowView"
      />
    ),
    RewardRowView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="RewardRowView"
      />
    ),
    DiscountRowView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="DiscountRowView"
      />
    ),
    AddedChargeRowView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="AddedChargeRowView"
      />
    ),
    OrderHistoryRowView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderHistoryRowView"
      />
    ),
    OrderHistoryEmptyView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="OrderHistoryEmptyView"
      />
    ),
    phoneNumberInputProps: {
      placeholder: 'Enter Phone Number',
    },
  },
  Auth: {
    AuthView: () => (
      <PlaceholderView
        style={{ minHeight: 1000, width: '100%' }}
        name="AuthView"
      />
    ),
    GoBackButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="GoBackButtonView"
      />
    ),
    NextButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="NextButtonView"
      />
    ),
    LoginButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="LoginButtonView"
      />
    ),
    SignupButtonView: () => (
      <PlaceholderView
        style={{ minHeight: 50, minWidth: 50 }}
        name="SignupButtonView"
      />
    ),
    emailInputProps: {
      placeholder: 'Enter Email',
    },
    passwordInputProps: {
      placeholder: 'Enter Password',
    },
    phoneInputProps: {
      placeholder: 'Enter Phone Number',
    },
  },
}

const ThemeContext = React.createContext(defaultValue)
// Assign to defaultValue so we can expose to restaurant specific code and do a deep merge
ThemeContext.defaultValue = defaultValue
export default ThemeContext
