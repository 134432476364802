import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, CFText } from 'components'
import HomeHeader from './HomeHeader'
import { hero, downloadButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        {/* <CFView column className={styles.text}>
          <CFText heading2 bold h1 color="white">
            Free milk tea on your first order!
          </CFText>
        </CFView> */}
        <CFView className={styles.buttons}>
          {OrderNowButtonElement}
          <Space width="10rem" />
          <CFLink href="http://onelink.to/dz6bd3">
            <img
              src={downloadButton}
              alt="Download App"
              className={styles.downloadButton}
            />
          </CFLink>
        </CFView>
      </div>
    )
  }
}
const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    boxShadow: '0 2px 2px rgba(0,0,0,.5)',
    backgroundPosition: 'center',
    position: 'relative',
    [MEDIA_QUERY.MD]: {
      height: '100vh',
      backgroundPosition: '40%',
    },
  }),
  text: css({
    position: 'absolute',
    right: '3%',
    bottom: '55%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    padding: '1rem 3rem 1rem 3rem',
    borderRadius: '3rem',
    [MEDIA_QUERY.MD]: {
      bottom: '30%',
      right: 'auto',
      padding: '1rem',
      textAlign: 'center',
      margin: '1rem',
    },
  }),
  buttons: css({
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '5%',
    right: '1%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      bottom: '.5rem',
      right: '12%',
      width: '75%',
    },
  }),
  downloadButton: css({
    width: '100%',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      paddingBottom: '2rem',
    },
  }),
}

export default Hero
