import React from 'react'
import { CFText, CFView, HorizontalLine, Space } from 'components'
import { css } from 'emotion'

const numbers = [
  'Many',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
]

export default ({
  modifier,
  ProductModifierChoicesElement,
  CloseButtonElement,
  NextButtonElement,
}) => (
  <div className={styles.container}>
    <CFText h2 bold black mb="1rem">
      {modifier.name}
    </CFText>
    <CFView row justifyBetween mb="1rem">
      <CFText h3 black>
        Choose {numbers[modifier.maxSelect]}
      </CFText>
      {modifier.required > 0 && (
        <CFText h3 black>
          (Required)
        </CFText>
      )}
    </CFView>
    <HorizontalLine color="black" />
    <div className={styles.choices}>
      <CFView column>{ProductModifierChoicesElement}</CFView>
    </div>
    <HorizontalLine color="black" />
    <CFView row mt="2rem">
      {CloseButtonElement}
      <Space w2 />
      {NextButtonElement}
    </CFView>
  </div>
)

const styles = {
  container: css({
    backgroundColor: 'white',
    maxWidth: '50rem',
    width: '99%',
    border: '3px solid #000',
    color: 'white',
    padding: '2rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  choices: css({
    maxHeight: '30rem',
    overflowY: 'auto',
    padding: '1rem',
  }),
}
