import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'
import { COLOR } from 'styles'

const StoreClosedBannerView = () => (
  <div className={styles.closed}>
    <Text heading h4 color="white">
      ORDERING CLOSED
    </Text>
  </div>
)

const styles = {
  closed: css({
    width: '100%',
    padding: '1rem 2rem 1rem 2rem',
    marginBottom: '1rem',
    backgroundColor: COLOR.red,
    borderRadius: '1rem',
    textAlign: 'center',
  }),
}

export default StoreClosedBannerView
