import React from 'react'
import {
  CFText,
  CFView,
  RadioSelectButtonView,
  CheckSelectButtonView,
} from 'components'
import { css } from 'emotion'
import { COLOR } from 'styles'

export default ({ modifier, choice }) => {
  const { name, selected, price } = choice
  return (
    <div className={styles.container}>
      <CFText content h3 color={selected ? COLOR.red : COLOR.black}>
        {name}
      </CFText>
      <CFView row alignCenter>
        {price > 0 && (
          <CFText
            content
            h4
            black
            bold
            color={selected ? COLOR.red : COLOR.black}
          >
            +${Number(price).toFixed(2)}
          </CFText>
        )}
        <CFView ml="2rem">
          {modifier.required === 1 && modifier.maxSelect === 1 ? (
            <RadioSelectButtonView selected={selected} />
          ) : (
            <CheckSelectButtonView selected={selected} />
          )}
        </CFView>
      </CFView>
    </div>
  )
}

const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '4rem',
  }),
}
