import React from 'react'
import { CFView, Text } from 'components'
import { css } from 'emotion'
import { pointsMeter, rewardbg, rewardDefault } from 'images'

const RewardsLoadedView = ({
  pointsMeterWidth,
  imageUrl,
  LeftArrowButtonElement,
  RightArrowButtonElement,
  points,
  maxPoints,
  requiredPoints,
  name,
  RedeemRewardButtonElement,
}) => (
  <div className={styles.rewardContainer}>
    <div className={styles.reward}>
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Reward" />
      ) : (
        <img
          className={styles.rewardDefault}
          src={rewardDefault}
          alt="Reward"
        />
      )}
    </div>
    {LeftArrowButtonElement}
    {RightArrowButtonElement}
    <div className={styles.meter}>
      <div
        className={styles.progress}
        style={{
          width: `${pointsMeterWidth * 29}rem`,
          transition: 'width 2s',
        }}
      >
        <img className={styles.pointsMeter} src={pointsMeter} alt="Meter" />
      </div>
    </div>
    <div className={styles.pointsText}>
      <Text h3 amatic black>
        {points} / {maxPoints}
      </Text>
    </div>
    <Text amatic h1 black className={styles.requiredPoints}>
      {requiredPoints === 0 ? 'FREE' : `${requiredPoints} Points`}
    </Text>
    <CFView className={styles.name}>
      <Text amatic h1 dark center>
        {name ? name.toUpperCase() : ''}
      </Text>
    </CFView>
    <CFView className={styles.redeemButton}>{RedeemRewardButtonElement}</CFView>
  </div>
)

const styles = {
  rewardContainer: css({
    borderRadius: '1rem',
    position: 'relative',
    height: '100%',
    width: '100%',
    background: `url(${rewardbg}) no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '2px 2px 8px rgba(0,0,0,.5)',
  }),
  reward: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    position: 'relative',
    width: '22.5rem',
    height: '15.5rem',
    borderRadius: '1rem',
    left: '.1rem',
    top: '3.5rem',
    boxShadow: '0 0 8px rgba(0,0,0,.5)',
    objectFit: 'cover',
  }),
  rewardDefault: css({
    position: 'relative',
    objectFit: 'contain',
    width: '14rem',
    top: '3.7rem',
  }),
  requiredPoints: css({
    textTransform: 'uppercase',
    position: 'absolute',
    top: '13rem',
  }),
  name: css({
    width: '100%',
    fontSize: '2rem',
    position: 'absolute',
    bottom: '11rem',
    verticalAlign: 'top',
    textAlign: 'center',
  }),
  redeemButton: css({
    position: 'absolute',
    bottom: '3rem',
  }),
  meter: css({
    position: 'absolute',
    top: '4.66rem',
    left: '7.15rem',
  }),
  progress: css({
    overflow: 'hidden',
  }),
  pointsMeter: css({
    objectFit: 'contain',
    width: '28.95rem',
  }),
  pointsText: css({
    position: 'absolute',
    top: '8rem',
    right: '1.9rem',
  }),
}

export default RewardsLoadedView
