import React from 'react'
import { ThemeContext } from 'cf-web-app'
import { CFButton, CFInput, CFModal } from 'components'
import { firebaseAuth } from 'cf-core/src/config/firebase'
import { dispatch } from 'cf-web-app/store'

export default class ForgotPasswordButton extends React.PureComponent {
  emailInput = React.createRef()
  state = { showModal: false, submitting: false }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Auth, Components }) => (
          <>
            <CFButton onClick={() => this.setState({ showModal: true })}>
              <Auth.ForgotPasswordButtonView />
            </CFButton>
            <CFModal isOpen={this.state.showModal}>
              <Auth.ForgotPasswordModalView
                EmailInputElement={
                  <CFInput
                    ref={this.emailInput}
                    onKeyPress={this._handleKeyPress}
                    {...Auth.emailInputProps}
                  />
                }
                CancelButtonElement={
                  <CFButton onClick={() => this.setState({ showModal: false })}>
                    <Components.PrimaryButtonView label="CANCEL" />
                  </CFButton>
                }
                SubmitButtonElement={
                  <CFButton
                    onClick={this._handleForgotPassword}
                    disabled={this.state.submitting}
                  >
                    <Components.PrimaryButtonView
                      disabled={this.state.submitting}
                      label={'SUBMIT'}
                    />
                  </CFButton>
                }
              />
            </CFModal>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      this._handleForgotPassword(e)
    }
  }
  _handleForgotPassword = e => {
    e.preventDefault()
    const email = this.emailInput.current.value.trim()
    if (!email) {
      dispatch.notification.setMessage({
        message: `Email cannot be empty`,
        level: 'error',
      })
      return
    }
    this.setState({ submitting: true })
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch.notification.setMessage({
          message: `Password reset sent to ${email}`,
          level: 'success',
        })
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
      .finally(() => {
        this.setState({ submitting: false, showModal: false })
      })
  }
}
