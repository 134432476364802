import React, { Component } from 'react'
import { CFButton, CFSelect } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class Rewards extends Component {
  state = { index: 0, pointsMeterWidth: 0 }
  componentDidMount() {
    requestAnimationFrame(() => {
      const { points } = this.props
      const pointsMeterWidth =
        points >= dispatch.user.getPointsMax()
          ? 1
          : (1 * points) / dispatch.user.getPointsMax()
      this.setState({ pointsMeterWidth })
    })
  }
  componentDidUpdate(prevProps) {
    const { points } = this.props
    if (prevProps.points !== points) {
      const pointsMeterWidth =
        points >= dispatch.user.getPointsMax()
          ? 1
          : (1 * points) / dispatch.user.getPointsMax()
      this.setState({ pointsMeterWidth })
    }
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.RewardsContainerView
            RewardsElement={
              <CFSelect selector={dispatch.restaurant.getLoading}>
                {loading =>
                  loading ? null : (
                    <CFSelect
                      selector={[
                        dispatch.user.getPointsWithPromoApplied,
                        dispatch.user.getRewardsWithDetailsSorted,
                      ]}
                    >
                      {([points, rewards]) => {
                        const reward = rewards[this.state.index] || {}
                        return (
                          <Cart.RewardsLoadedView
                            pointsMeterWidth={this.state.pointsMeterWidth}
                            imageUrl={reward.imageUrl}
                            LeftArrowButtonElement={
                              <CFButton
                                disabled={this.state.index === 0}
                                onClick={() =>
                                  this.setState(state => ({
                                    index: state.index - 1,
                                  }))
                                }
                              >
                                <Cart.RewardsLeftArrowButtonView
                                  disabled={this.state.index === 0}
                                />
                              </CFButton>
                            }
                            RightArrowButtonElement={
                              <CFButton
                                disabled={
                                  this.state.index === rewards.length - 1
                                }
                                onClick={() =>
                                  this.setState(state => ({
                                    index: state.index + 1,
                                  }))
                                }
                              >
                                <Cart.RewardsRightArrowButtonView
                                  disabled={
                                    this.state.index === rewards.length - 1
                                  }
                                />
                              </CFButton>
                            }
                            points={points}
                            maxPoints={
                              <CFSelect selector={dispatch.user.getPointsMax} />
                            }
                            requiredPoints={reward.requiredPoints}
                            name={reward.name}
                            RedeemRewardButtonElement={
                              <CFButton
                                onClick={() => this._handleRedeemPress(reward)}
                              >
                                <Cart.RewardsRedeemButtonView />
                              </CFButton>
                            }
                          />
                        )
                      }}
                    </CFSelect>
                  )
                }
              </CFSelect>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleRedeemPress = reward => {
    if (reward.valid) {
      dispatch.user.addPromo(reward.id).then(() => {
        dispatch.notification.setMessage({
          message: `${reward.name} redeemed!`,
          level: 'success',
        })
      })
    } else {
      dispatch.notification.setMessage({
        message: reward.message,
        level: 'error',
      })
    }
  }
}
