import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const OrderHistoryEmptyView = () => (
  <div className={styles.orderHistory} style={{ padding: '8rem 0 8rem 0' }}>
    <Text heading h1 bold color="black">
      No Orders Yet.
    </Text>
  </div>
)

const styles = {
  orderHistory: css({
    width: '100%',
    textAlign: 'center',
  }),
}

export default OrderHistoryEmptyView
