// FIREBASE FOR CLIENT (MOBILE AND WEB)
let firebase = null
let storageRef = null
let isMobile = false
try {
  firebase = require('react-native-firebase')
  isMobile = true
  try {
    storageRef = firebase.storage().ref()
  } catch (e) {
    console.warn(e.message)
  }
} catch (e) {
  // This is probably web
  firebase = require('firebase/app')
  require('firebase/firestore')
  require('firebase/auth')

  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  }
  firebase.initializeApp(config)
}

// This causes no-undef error in create-react-app
// __DEV__ && firebase.firestore.setLogLevel('debug')

const settings = { timestampsInSnapshots: true }
const firestore = firebase.firestore()
firestore.settings(settings)

const firebaseAuth = firebase.auth()

const db = firestore

const getRestaurantsCollection = () => firestore.collection('Restaurants')

const getRestaurantDoc = ({ restaurantId }) => firestore.collection('Restaurants').doc(restaurantId)
const getRestaurantLocationsCollection = ({ restaurantId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
const getRestaurantLocationDoc = ({ restaurantId, locationId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)

const getRestaurantOrdersCollection = ({ restaurantId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')

const getRestaurantOrderDoc = ({ restaurantId, orderId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .doc(orderId)

const getUsersCollection = () => firestore.collection('Users')

const getUserDoc = ({ userId }) => firestore.collection('Users').doc(userId)
const getUserRestaurantDoc = ({ userId, restaurantId }) =>
  firestore
    .collection('Users')
    .doc(userId)
    .collection('Restaurants')
    .doc(restaurantId)

const DELETE_FIELD_VALUE = firebase.firestore.FieldValue.delete()

export {
  firebaseAuth,
  storageRef,
  isMobile,
  // Firestore
  db,
  DELETE_FIELD_VALUE,
  getRestaurantsCollection,
  getRestaurantDoc,
  getRestaurantLocationsCollection,
  getRestaurantLocationDoc,
  getRestaurantOrdersCollection,
  getRestaurantOrderDoc,
  getUsersCollection,
  getUserDoc,
  getUserRestaurantDoc,
}
