import React from 'react'
import { CFText, CFView } from 'cf-web-app/components'
import { css } from 'emotion'

export default ({ name, className, children, disabled, ...props }) => (
  <CFView
    className={css(styles.view, disabled && styles.disabledView, className)}
    relative
    {...props}
  >
    <CFView absoluteFill center>
      <CFText>{name}</CFText>
    </CFView>
    {children}
  </CFView>
)

const styles = {
  view: css({
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'red',
  }),
  disabledView: css({ borderColor: '#800000' }),
}
