import React from 'react'
import { App, ThemeContext } from 'cf-web-app'
import { mergeWith, isObjectLike } from 'lodash'
import * as pages from 'pages'
import * as Components from 'components'

// ==========================================================================================
// All the logic now goes into cf-web-app except native code and WebApp specific code
// ==========================================================================================

// In order to only override theme properties that we provide, do a deep merge with a default theme
const defaultThemeValue = mergeWith(
  ThemeContext.defaultValue,
  {
    Components,
    ...pages,
  },
  (objValue, srcValue) => {
    if (isObjectLike(objValue) && isObjectLike(srcValue)) {
      return Object.assign(objValue, srcValue)
    }
  }
)

export default class EbisuApp extends React.PureComponent {
  render() {
    return (
      <ThemeContext.Provider value={defaultThemeValue}>
        <App />
      </ThemeContext.Provider>
    )
  }
}
