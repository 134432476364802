import React, { Component } from 'react'
import { CFSelect, PrimaryButton } from 'cf-web-app/components'
import UserDetails from './UserDetails'
import OrderHistory from './OrderHistory'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class User extends Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {({ User }) => (
          <User.UserView
            UserContentElement={
              <CFSelect selector={dispatch.user.getIsLoading}>
                {loading =>
                  loading ? (
                    <User.UserLoadingView />
                  ) : (
                    <User.UserLoadedView
                      UserDetailsElement={
                        <UserDetails history={this.props.history} />
                      }
                      LogoutButtonElement={
                        <PrimaryButton
                          label="LOGOUT"
                          onClick={this._handleLogoutClick}
                        />
                      }
                      OrderHistory={OrderHistory}
                    />
                  )
                }
              </CFSelect>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleLogoutClick = name => {
    const { history } = this.props
    history.push('/')
    dispatch.user.signOut()
    dispatch.notification.setMessage({
      message: 'Successfully logged out!',
      level: 'success',
    })
  }
}
