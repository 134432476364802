import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class OrderButton extends Component {
  render() {
    const { label, disabled, onClick } = this.props
    return (
      <CFButton disabled={disabled} onClick={onClick}>
        <ThemeContext.Consumer>
          {({ Components }) => (
            <Components.OrderButtonView label={label} disabled={disabled} />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}
