import React from 'react'
import { MediaQuery, OrderButtonView } from 'components'

export default ({ isMenuActive }) => (
  <MediaQuery maxWidth={576}>
    {matches => (
      <OrderButtonView
        label={
          isMenuActive
            ? matches
              ? 'ADD'
              : 'ADD TO CART'
            : matches
            ? 'CLOSED'
            : 'MENU CLOSED'
        }
        disabled={!isMenuActive}
      />
    )}
  </MediaQuery>
)
