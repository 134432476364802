import React, { Component } from 'react'
import { css } from 'emotion'
import { CFText } from 'cf-web/src/components'
import { COLOR } from 'cf-web/src/styles'

class CFInput extends Component {
  render() {
    const {
      className,
      disabled,
      multiline,
      error,
      forwardedRef,
      ...props
    } = this.props
    const classNames = css(
      multiline ? styles.multiline : styles.input,
      className
    )
    return (
      <div>
        {multiline ? (
          <textarea
            style={disabled ? { backgroundColor: 'rgba(255,255,255,0.4)' } : {}}
            ref={forwardedRef}
            className={classNames}
            disabled={disabled}
            {...props}
          />
        ) : (
          <input
            style={disabled ? { backgroundColor: 'rgba(255,255,255,0.4)' } : {}}
            ref={forwardedRef}
            className={classNames}
            disabled={disabled}
            {...props}
          />
        )}
        {error && (
          <CFText content h4 red>
            {error}
          </CFText>
        )}
      </div>
    )
  }
}

const styles = {
  input: css({
    paddingLeft: '1.5rem',
    height: '4rem',
    width: '100%',
    display: 'inline-block',
    borderRadius: '.5rem',
    boxShadow: `inset 1px 1px 1px ${COLOR.mediumGrey}`,
    outline: '0',
    border: 'none',
    color: COLOR.black,
    fontSize: '1.6rem',
    fontFamily: 'roboto',
    '&::placeholder': {
      color: COLOR.mediumGrey,
    },
  }),
  multiline: css({
    padding: '1rem',
    width: '100%',
    height: '10rem',
    display: 'inline-block',
    borderRadius: '.5rem',
    resize: 'vertical',
    boxShadow: `inset 1px 1px 1px ${COLOR.mediumGrey}`,
    outline: '0',
    border: 'none',
    color: COLOR.black,
    fontSize: '1.6rem',
    fontFamily: 'roboto',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
  }),
}

export default React.forwardRef((props, ref) => {
  return <CFInput forwardedRef={ref} {...props} />
})
