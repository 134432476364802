import React from 'react'
import { CFView, Space } from 'components'

export default ({
  orderType,
  deliveryEnabled,
  PickupButtonElement,
  DeliveryButtonElement,
  TimeAndPlaceElement,
  TipElement,
}) => (
  <CFView column>
    {deliveryEnabled && (
      <CFView row maxSMStyle={{ justifyContent: 'center' }}>
        {PickupButtonElement}
        <Space w2 />
        {DeliveryButtonElement}
      </CFView>
    )}
    <CFView mt="2rem">{TimeAndPlaceElement}</CFView>
    <CFView mt="2rem">{TipElement}</CFView>
  </CFView>
)
