import React from 'react'
import { CFView, Space, Text, HorizontalLine } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'
import { moment } from 'cf-utils'

const OrderDetailsView = ({
  orderType,
  orderNumber,
  createdAt,
  OrderStatusElement,
  ProductListElement,
  ChoicesProductListElement,
  RewardListElement,
  DiscountElement,
  AddedChargesElement,
  subTotalFormatted,
  firstOrderDiscountFormatted,
  deliveryFeeFormatted,
  tipFormatted,
  taxFormatted,
  totalFormatted,
}) => (
  <div className={styles.orderDetails}>
    <CFView fill row justifyBetween alignCenter>
      <Text heading h1 red>{`#${orderNumber}`}</Text>
      <Text heading h3 black>{`${moment(createdAt).format('MMMM D')}, ${moment(
        createdAt
      ).format('LT')}`}</Text>
    </CFView>
    <Space h3 />
    <CFView fill row justifyCenter alignCenter>
      {OrderStatusElement}
    </CFView>
    <Space h3 />
    <div>
      {ProductListElement}
      {ChoicesProductListElement}
      {RewardListElement}
      {DiscountElement}
      {AddedChargesElement}
    </div>
    <Space h1 />
    <HorizontalLine color="black" />
    <Space h1 />
    <div className={styles.column}>
      {firstOrderDiscountFormatted > 0 && (
        <div className={styles.productItem}>
          <Text content h4 black>
            First Order Discount
          </Text>
          <Text content h4 black>{`-$${firstOrderDiscountFormatted}`}</Text>
        </div>
      )}
      <div className={styles.productItem}>
        <Text content h4 black>
          Subtotal
        </Text>
        <Text content h4 black>{`$${subTotalFormatted}`}</Text>
      </div>
      {orderType === 'Delivery' && (
        <div className={styles.productItem}>
          <Text content h4 black>
            Delivery Fee
          </Text>
          <Text content h4 black>{`$${deliveryFeeFormatted}`}</Text>
        </div>
      )}
      <div className={styles.productItem}>
        <Text content h4 black>
          GST (5%)
        </Text>
        <Text content h4 black>{`$${taxFormatted}`}</Text>
      </div>
      {Number(tipFormatted) > 0 && (
        <div className={styles.productItem}>
          <Text content h4 black>
            Tip
          </Text>
          <Text content h4 black>{`$${tipFormatted}`}</Text>
        </div>
      )}
      <div className={styles.productItem}>
        <Text content h4 black>
          Total
        </Text>
        <Text content h4 black>{`$${totalFormatted}`}</Text>
      </div>
    </div>
  </div>
)

const styles = {
  orderDetails: css({
    color: 'white',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '60rem',
    [MEDIA_QUERY.SM]: {
      width: '100%',
    },
  }),
  column: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
}

export default OrderDetailsView
