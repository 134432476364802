import axios from 'axios'

export function createOrderAndCharge(
  serverUrl,
  authToken,
  restaurantId,
  locationId,
  order
) {
  const config = {
    url: `${serverUrl}/api/create_order`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: {
      ...order,
      restaurantId,
      locationId,
    },
  }
  return axios(config)
}

export function createCustomerWithStripe(serverUrl, authToken, cardToken) {
  const config = {
    url: `${serverUrl}/api/create_customer`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: cardToken,
  }
  return axios(config)
}

export function updateCustomerWithStripe(serverUrl, authToken, cardToken) {
  const config = {
    url: `${serverUrl}/api/update_customer`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: cardToken,
  }
  return axios(config)
}
