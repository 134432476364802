import React from 'react'
import { CFSelect } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'

export default class SubscribeRestaurantLocation extends React.PureComponent {
  locationId = null
  unsubscribe = null
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe()
  }
  render() {
    return (
      <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
        {locationId => {
          if (locationId !== this.locationId) {
            if (locationId) {
              this.unsubscribe = dispatch.restaurant.subscribeRestaurantLocation()
            } else {
              dispatch.restaurant.resetRestaurantLocation()
              this.unsubscribe && this.unsubscribe()
            }
            this.locationId = locationId
          }
          return null
        }}
      </CFSelect>
    )
  }
}
