export { default as AuthView } from './AuthView'
export { default as ForgotPasswordButtonView } from './ForgotPasswordButtonView'
export { default as ForgotPasswordModalView } from './ForgotPasswordModalView'
export { default as GoBackButtonView } from './GoBackButtonView'
export { default as NextButtonView } from './NextButtonView'
export { default as LoginButtonView } from './LoginButtonView'
export { default as SignupButtonView } from './SignupButtonView'

export const emailInputProps = {
  placeholder: 'Enter Email',
}

export const passwordInputProps = {
  placeholder: 'Enter Password',
}

export const nameInputProps = {
  placeholder: 'Enter Name',
}

export const phoneInputProps = {
  placeholder: 'Enter Phone Number',
}
