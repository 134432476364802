export default {
  state: {
    message: '',
    level: '',
    position: '',
  },
  reducers: {
    setMessage(state, { message, level, position }) {
      return {
        ...state,
        message,
        level,
        position,
      }
    },
  },
}
