import React from 'react'
import { Container, Space } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const CartView = ({ RewardsElement, SummaryReceiptElement }) => (
  <Container>
    <div className={styles.content}>
      <div className={styles.border}>
        {RewardsElement}
        <Space width="4rem" height="4rem" />
        {SummaryReceiptElement}
      </div>
    </div>
  </Container>
)

const styles = {
  content: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.5,
    minHeight: 'calc(100vh - 7rem)',
    overflow: 'hidden',
  }),
  border: css({
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    maxWidth: '120rem',
    padding: '2rem 4rem 2rem 4rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '1rem',
    border: '.2rem solid black',
    [MEDIA_QUERY.LG]: {
      flexDirection: 'column-reverse',
      padding: '2rem',
    },
  }),
}

export default CartView
