import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class CartItem extends Component {
  render() {
    const { count, name, price } = this.props
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.CartItemView
            count={count}
            name={name}
            price={price}
            CancelButtonElement={
              <CFButton onClick={this._handleRemoveClick}>
                <Cart.CartItemRemoveButtonView />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleRemoveClick = () => {
    const { id, name } = this.props
    dispatch.user.subtractCartItem({ id })
    dispatch.notification.setMessage({
      message: `${name} removed from Cart`,
      level: 'success',
    })
  }
}
