import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class CategoryItem extends Component {
  render() {
    const { id, name, onCategorySelect, selected, imageUrl, index } = this.props
    return (
      <CFButton onClick={() => onCategorySelect(id)}>
        <ThemeContext.Consumer>
          {({ Menu }) => (
            <Menu.CategoryItemView
              imageUrl={imageUrl}
              isSelected={selected === id}
              name={name}
              nameFormatted={name.toUpperCase()}
              index={index}
            />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}
