import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const AddedChargeRowView = ({ name, priceFormatted }) => (
  <div className={styles.productItem}>
    <Text content h4 black>
      {name}
    </Text>
    <Text content h4 black>
      ${priceFormatted}
    </Text>
  </div>
)

const styles = {
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
}

export default AddedChargeRowView
