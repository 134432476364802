import React, { Component } from 'react'
import { css } from 'emotion'
import { CFInput, CFText, CFView } from 'cf-web/src/components'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

const options = {
  componentRestrictions: { country: ['ca'] },
  types: ['address'],
}

export default class extends Component {
  render() {
    return (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.props.onChange}
        onSelect={this._handleSelect}
        searchOptions={options}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <CFView column className={styles.container}>
            <CFInput
              {...getInputProps()}
              placeholder="Enter Address"
              onKeyPress={e => {
                e.target.keyCode === 13 && e.preventDefault()
              }}
            />
            <div className={styles.placesList}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => (
                <div
                  className={styles.placeItem}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <CFText h4 white>
                    {suggestion.description}
                  </CFText>
                </div>
              ))}
            </div>
          </CFView>
        )}
      </PlacesAutocomplete>
    )
  }
  _handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.coordinate(latLng)
        this.props.onSelect(address)
      })
  }
}

const styles = {
  container: css({
    position: 'relative',
  }),
  placesList: css({
    position: 'absolute',
    backgroundColor: 'black',
    top: '4rem',
    width: '100%',
    maxHeight: '12rem',
    overflowY: 'auto',
  }),
  placeItem: css({
    height: '4rem',
    padding: '1rem',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  }),
}
