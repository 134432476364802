import React from 'react'
import { CFView } from 'components'
import { css } from 'emotion'
import * as images from 'images'

const RewardsRedeemButtonView = () => (
  <CFView className={styles.redeemButton}>
    <img
      className={styles.button}
      src={images.redeemButton}
      alt="Redeem Button"
    />
  </CFView>
)

const styles = {
  redeemButton: css({
    animation: 'pulsate 1s infinite',
    '@keyframes pulsate': {
      '0%': {
        transform: 'scaleX(1) scaleY(1)',
      },
      '50%': {
        transform: 'scaleX(1.05) scaleY(1.05)',
      },
    },
  }),
  button: css({
    width: '20rem',
  }),
}

export default RewardsRedeemButtonView
