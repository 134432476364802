import React from 'react'
import { Text, Space } from 'components'
import { css } from 'emotion'

const ProductItemView = ({
  nameFormatted,
  isSoldOut,
  isProductDetailsOpen,
  isOptionsEmpty,
  priceFormatted,
  CloseProductDetailsButtonElement,
  OpenProductDetailsButtonElement,
  description,
  OptionsListElement,
  SubtractQtyButtonElement,
  IncrementQtyButtonElement,
  quantity,
  AddToCartButtonElement,
  ProductModifierModalElement,
}) => (
  <div className={styles.container}>
    <div className={styles.row}>
      <Text din h4 dark xBold>
        {nameFormatted}
      </Text>
      {isSoldOut ? (
        <Text heading2 h4 red bold>
          SOLD OUT
        </Text>
      ) : (
        <div className={styles.add}>
          {isProductDetailsOpen ? (
            isOptionsEmpty && (
              <Text content h4 dark>
                ${priceFormatted}
              </Text>
            )
          ) : (
            <Text content h4 dark>
              ${priceFormatted}
            </Text>
          )}
          <Space w2 />
          {isProductDetailsOpen
            ? CloseProductDetailsButtonElement
            : OpenProductDetailsButtonElement}
        </div>
      )}
    </div>
    <div className={styles.description}>
      <Text content h6 grey>
        {description}
      </Text>
    </div>
    {OptionsListElement}
    {!isSoldOut && isProductDetailsOpen && (
      <div className={styles.orderBar}>
        <div className={styles.quantity}>
          {SubtractQtyButtonElement}
          <Space w2 />
          <Text heading h3 dark bold>
            {quantity}
          </Text>
          <Space w2 />
          {IncrementQtyButtonElement}
        </div>
        {AddToCartButtonElement}
      </div>
    )}
    {ProductModifierModalElement}
  </div>
)

const styles = {
  container: css({
    marginBottom: '1rem',
    backgroundColor: 'rgba(255, 255, 255, .7)',
    borderRadius: '1rem',
    border: '.2rem solid black',
  }),
  row: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.8rem 1.5rem 0 1.5rem',
  }),
  description: css({
    padding: '.4rem 1.5rem .8rem 1.5rem',
  }),
  add: css({
    display: 'flex',
    alignItems: 'center',
  }),
  orderBar: css({
    padding: '.5rem 1.5rem .5rem 1.5rem',
    backgroundColor: 'rgba(255, 255, 255, .7)',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  }),
  quantity: css({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  }),
}

export default ProductItemView
