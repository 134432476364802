import React, { Component } from 'react'
import { CFButton } from 'cf-web-app/components'
import { ThemeContext } from 'cf-web-app'

export default class EditButton extends Component {
  render() {
    const { onClick, disabled } = this.props
    return (
      <CFButton onClick={onClick} disabled={disabled}>
        <ThemeContext.Consumer>
          {({ Components }) => (
            <Components.EditButtonView disabled={disabled} />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
}
