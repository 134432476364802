import React, { Component } from 'react'
import { css } from 'emotion'
import { NavLink } from 'react-router-dom'
import { Text, MediaQuery } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'styles'

class IconLink extends Component {
  render() {
    const { className, children, label, icon, ...props } = this.props
    const classNames = css(styles.link, className)
    return (
      <NavLink
        className={classNames}
        activeStyle={{
          color: COLOR.red,
        }}
        {...props}
      >
        <FontAwesomeIcon className={styles.icon} icon={icon} />
        <MediaQuery maxWidth={767}>
          {matches => {
            if (matches) {
              return (
                <Text blowbrush h4 className={styles.label}>
                  {label}
                </Text>
              )
            }
            return (
              <Text blowbrush h3 className={styles.label}>
                {label}
              </Text>
            )
          }}
        </MediaQuery>
      </NavLink>
    )
  }
}

const styles = {
  link: css({
    color: '#202020',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }),
  icon: css({
    marginRight: '1rem',
    fontSize: '2.5rem',
  }),
  label: css({
    letterSpacing: '0.4rem',
  }),
}

export default IconLink
