import axios from 'axios'

export function createCardWithStripe(cardInfo, stripeKey) {
  const { number, cvc, exp_month, exp_year } = cardInfo
  const card = {
    'card[number]': number,
    'card[cvc]': cvc,
    'card[exp_month]': exp_month,
    'card[exp_year]': exp_year,
  }
  const url = 'https://api.stripe.com/v1/tokens'
  let formBody = []
  for (let property in card) {
    let encodedKey = encodeURIComponent(property)
    let encodedValue = encodeURIComponent(card[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }
  formBody = formBody.join('&')
  return axios({
    url,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + stripeKey,
    },
    data: formBody,
  })
}
