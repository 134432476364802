import { firebaseAuth } from '../config/firebase'

export function signInWithEmailAndPassword(email, password) {
  // this function can sometimes throw Error therefore requires try catch
  return new Promise((resolve, reject) => {
    try {
      firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(resolve)
        .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

export function createUserWithEmailAndPassword(email, password) {
  // this function can sometimes throw Error therefore requires try catch
  return new Promise((resolve, reject) => {
    try {
      firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then(resolve)
        .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

export function isEmailExists(email) {
  return new Promise((resolve, reject) => {
    firebaseAuth
      .fetchSignInMethodsForEmail(email)
      .then(res => {
        if (res.includes('password')) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}

export function getUserToken() {
  return firebaseAuth.currentUser.getIdToken()
}

export function getCurrentUser() {
  return firebaseAuth.currentUser
}

export function signOut() {
  return firebaseAuth.signOut()
}
