import React, { Component } from 'react'
import OrderDetailsModal from './OrderDetailsModal'
import { dispatch } from 'cf-web-app/store'
import { CFButton } from 'cf-web-app/components'
import { moment } from 'cf-utils'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'cf-web-app'

export default class OrderHistory extends Component {
  state = {
    orders: [],
    order: {},
    isOpen: false,
    loading: true,
  }
  componentDidMount() {
    if (dispatch.user.getIsLoggedIn()) {
      const fromDate = moment()
        .startOf('day')
        .subtract(1, 'years')
      const toDate = moment().endOf('day')
      dispatch.user
        .getOrderHistoryRange({
          fromDate,
          toDate,
        })
        .then(orders => {
          let lastMonth
          const monthedOrders = orders.map(order => {
            const orderMonth = moment(order.createdAt).format('MMMM')
            if (lastMonth !== orderMonth) {
              lastMonth = orderMonth
              return { ...order, subHeader: orderMonth }
            } else {
              return order
            }
          })
          this.setState({ orders: monthedOrders, loading: false })
        })
        .catch(e => {
          this.setState({ loading: false })
          dispatch.notification.setMessage({
            message: e.message,
            level: 'error',
          })
        })
    }
  }
  render() {
    const { orders, loading } = this.state
    return loading ? (
      <ThemeContext.Consumer>
        {({ User }) => <User.OrderHistorySpinnerView />}
      </ThemeContext.Consumer>
    ) : !isEmpty(orders) ? (
      <ThemeContext.Consumer>
        {({ User }) => (
          <User.OrderHistoryView
            OrdersListElement={orders.map(order => {
              const { createdAt, orderNumber, status, total } = order
              return (
                <CFButton
                  style={{ width: '100%' }}
                  key={orderNumber}
                  onClick={() => this._handleOrderClick(order)}
                >
                  <User.OrderHistoryRowView
                    orderNumber={orderNumber}
                    createdAtFormatted={`${moment(createdAt).format(
                      'MMMM D'
                    )}, ${moment(createdAt).format('LT')}`}
                    status={status}
                    totalFormatted={total.toFixed(2)}
                  />
                </CFButton>
              )
            })}
            OrderDetailsModalElement={
              this.state.isOpen && (
                <OrderDetailsModal
                  order={this.state.order}
                  isOpen={this.state.isOpen}
                  onRequestClose={() => this.setState({ isOpen: false })}
                />
              )
            }
          />
        )}
      </ThemeContext.Consumer>
    ) : (
      <ThemeContext.Consumer>
        {({ User }) => <User.OrderHistoryEmptyView />}
      </ThemeContext.Consumer>
    )
  }
  _handleOrderClick = order => {
    this.setState({ isOpen: true, order })
  }
}
