import React from 'react'
import { logo, logoTitle } from 'images'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const LogoView = ({ className }) => (
  <div className={css(styles.container, className)}>
    <img className={styles.logo} src={logo} alt="Typhoon BBT Cafe" />
    <img className={styles.logoTitle} src={logoTitle} alt="Typhoon BBT Cafe" />
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.SM]: {
      margin: 0,
      alignItems: 'center',
    },
  }),
  logo: css({
    marginTop: '.5rem',
    objectFit: 'contain',
    height: '14rem',
    [MEDIA_QUERY.SM]: {
      marginTop: '1rem',
      height: '6rem',
    },
  }),
  logoTitle: css({
    marginTop: '2rem',
    objectFit: 'contain',
    height: '4rem',
    [MEDIA_QUERY.SM]: {
      marginTop: '1rem',
      marginLeft: '1rem',
      height: '3rem',
    },
  }),
}

export default LogoView
