export { default as UserView } from './UserView'
export { default as UserLoadingView } from './UserLoadingView'
export { default as UserLoadedView } from './UserLoadedView'
export { default as UserDetailsView } from './UserDetailsView'
export { default as UserDetailsPaymentView } from './UserDetailsPaymentView'
export { default as UserDetailsTextView } from './UserDetailsTextView'
export { default as OrderHistoryView } from './OrderHistoryView'
export { default as OrderHistorySpinnerView } from './OrderHistorySpinnerView'
export { default as OrderDetailsModalView } from './OrderDetailsModalView'
export { default as OrderDetailsView } from './OrderDetailsView'
export { default as ProductRowView } from './ProductRowView'
export { default as ChoicesProductRowView } from './ChoicesProductRowView'
export { default as ChoiceRowView } from './ChoiceRowView'
export { default as RewardRowView } from './RewardRowView'
export { default as DiscountRowView } from './DiscountRowView'
export { default as AddedChargeRowView } from './AddedChargeRowView'
export { default as OrderHistoryRowView } from './OrderHistoryRowView'
export { default as OrderHistoryEmptyView } from './OrderHistoryEmptyView'

export const phoneNumberInputProps = {
  placeholder: 'Enter Phone Number',
}
export const nameInputProps = {
  placeholder: 'Enter Name',
}
