import React, { Component } from 'react'
import ProductItem from './ProductItem'
import { CFSelect } from 'cf-web-app/components'
import { get, pick } from 'lodash'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'

export default class Products extends Component {
  render() {
    return (
      <CFSelect
        selector={{
          categories: dispatch.restaurant.getCategories,
          products: dispatch.restaurant.getProducts,
          recentOrders: dispatch.user.getRecentOrdersSorted,
        }}
      >
        {this._renderProducts}
      </CFSelect>
    )
  }
  _renderProducts = ({ categories, products, recentOrders }) => {
    const { menuId, categoryId } = this.props
    let categoryProducts = []
    if (categoryId === 'recent') {
      categoryProducts = Object.values(pick(products, recentOrders))
    } else {
      const productOrder = dispatch.restaurant.getProductOrder(categoryId)
      categoryProducts = productOrder.reduce((prev, productId) => {
        const product = products[productId]
        if (product) {
          prev.push(product)
        }
        return prev
      }, [])
    }
    if (categoryId === 'recent' && categoryProducts.length < 1) {
      return (
        <ThemeContext.Consumer>
          {({ Menu }) => <Menu.EmptyRecentOrderView />}
        </ThemeContext.Consumer>
      )
    }
    return (
      <ThemeContext.Consumer>
        {({ Menu }) => (
          <Menu.ProductsItemContainerView
            categoryDescription={get(categories[categoryId], 'description', '')}
            ProductsListElement={categoryProducts.map(product => {
              const { id } = product
              return <ProductItem key={id} menuId={menuId} {...product} />
            })}
          />
        )}
      </ThemeContext.Consumer>
    )
  }
}
