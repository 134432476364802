import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const SummaryTotalView = ({
  subTotalFormatted,
  taxFormatted,
  totalFormatted,
  tipFormatted,
  deliveryFeeFormatted,
  firstOrderDiscount,
  orderType,
}) => (
  <>
    {firstOrderDiscount > 0 && (
      <div className={styles.totalRow}>
        <Text content h4 black>
          First Order Discount (10%)
        </Text>
        <Text content h4 black>
          -${firstOrderDiscount}
        </Text>
      </div>
    )}
    <div className={styles.totalRow}>
      <Text content h4 black>
        Subtotal
      </Text>
      <Text content h4 black>
        ${subTotalFormatted}
      </Text>
    </div>
    {orderType === 'Delivery' && (
      <div className={styles.totalRow}>
        <Text content h4 black>
          Delivery Fee
        </Text>
        <Text content h4 black>
          ${deliveryFeeFormatted}
        </Text>
      </div>
    )}
    <div className={styles.totalRow}>
      <Text content h4 black>
        GST (5%)
      </Text>
      <Text content h4 black>
        ${taxFormatted}
      </Text>
    </div>
    <div className={styles.totalRow}>
      <Text content h4 black>
        Tip
      </Text>
      <Text content h4 black>
        ${tipFormatted}
      </Text>
    </div>
    <div className={styles.totalRow}>
      <Text content h4 black>
        Total
      </Text>
      <Text content h4 black>
        ${totalFormatted}
      </Text>
    </div>
  </>
)

const styles = {
  totalRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '3rem',
  }),
}

export default SummaryTotalView
