import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const ProductsItemContainerView = ({
  categoryDescription,
  ProductsListElement,
}) => (
  <div className={styles.container}>
    <div className={styles.list}>
      {!!categoryDescription && (
        <div className={styles.description}>
          <Text content h6 grey>
            {categoryDescription}
          </Text>
        </div>
      )}
      {ProductsListElement}
    </div>
  </div>
)

const styles = {
  container: css({
    flex: 1,
    overflowY: 'auto',
  }),
  list: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1rem 0 1rem',
  }),
  description: css({
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '1rem',
    padding: '.8rem 1.5rem .8rem 1.5rem',
    borderRadius: '1rem',
    border: '.2rem solid black',
  }),
}

export default ProductsItemContainerView
