import { db } from 'cf-core/src/config/firebase'

export function createUser(userId, userDetails) {
  return db
    .collection('Users')
    .doc(userId)
    .set(userDetails, { merge: true })
}

export function updateUser(userId, userDetails) {
  return db
    .collection('Users')
    .doc(userId)
    .update(userDetails)
}

export function updateUserRestaurant(userId, restaurantId, userRestaurantDetails) {
  const restaurantDoc = db
    .collection('Users')
    .doc(userId)
    .collection('Restaurants')
    .doc(restaurantId)

  return restaurantDoc.update(userRestaurantDetails).catch(() => {
    // Update will fail when restaurantId doc doesn't exist. Use Set instead
    return restaurantDoc.set(userRestaurantDetails)
  })
}
