import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'
import { CFButton, CFInput } from 'cf-web-app/components'

class Payment extends Component {
  state = {
    number: '',
    expiry: '',
    cvc: '',
    saving: false,
  }
  render() {
    const { expiry, number, cvc, saving } = this.state
    const fields = {
      number: this._formatNumber(number),
      expiry: this._formatExpiry(expiry),
      cvc,
    }
    return (
      <ThemeContext.Consumer>
        {({ Payment }) => (
          <Payment.PaymentView
            CardNumberInputElement={
              <CFInput
                maxLength="19"
                name="number"
                onChange={this._handleInputChange}
                value={fields.number}
                {...Payment.cardNumberInputProps}
              />
            }
            ExpiryInputElement={
              <CFInput
                maxLength="5"
                name="expiry"
                onChange={this._handleInputChange}
                value={fields.expiry}
                {...Payment.expiryInputProps}
              />
            }
            CVCInputElement={
              <CFInput
                maxLength="3"
                name="cvc"
                onChange={this._handleInputChange}
                value={fields.cvc}
                {...Payment.CVCInputProps}
              />
            }
            SubmitButtonElement={
              <CFButton disabled={saving} onClick={this._handleSubmitPress}>
                <Payment.SubmitButtonView disabled={saving} saving={saving} />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleInputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    if (name === 'number') {
      const number = value.replace(/\D/g, '')
      this.setState({ number })
    } else if (name === 'expiry') {
      const expiry = value.replace(/\D/g, '')
      this.setState({ expiry })
    } else if (name === 'cvc') {
      const cvc = value.replace(/\D/g, '')
      this.setState({ cvc })
    }
  }
  _handleSubmitPress = () => {
    this.setState({ saving: true })
    const { number, expiry, cvc } = this.state
    const card = {
      number,
      cvc,
      exp_month: expiry.slice(0, 2),
      exp_year: `20${expiry.slice(2, 4)}`,
    }
    const { history, match } = this.props
    const { nextRoute } = match.params
    if (dispatch.user.getHasPayment()) {
      dispatch.user
        .updateCreditCard(card)
        .then(() => {
          this.setState({ saving: false })
          history.push(`/${nextRoute}`)
          dispatch.notification.setMessage({
            message: 'Credit Card updated!',
            level: 'success',
          })
        })
        .catch(() => {
          this.setState({ saving: false })
          dispatch.notification.setMessage({
            message: 'Error processing credit card!',
            level: 'error',
          })
        })
    } else {
      dispatch.user
        .addCreditCard(card)
        .then(() => {
          this.setState({ saving: false })
          history.push(`/${nextRoute}`)
          dispatch.notification.setMessage({
            message: 'Credit Card added!',
            level: 'success',
          })
        })
        .catch(e => {
          this.setState({ saving: false })
          dispatch.notification.setMessage({
            message: e.message,
            level: 'error',
          })
        })
    }
  }
  _formatNumber = cc => {
    return cc.replace(/(.{4})/g, '$1 ').trim()
  }
  _formatExpiry = exp => {
    if (exp.length < 3) {
      return exp
    }
    return exp.substr(0, 2) + '/' + exp.substr(2, 2)
  }
}

export default withRouter(Payment)
