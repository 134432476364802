import React from 'react'
import { css } from 'emotion'
import { Container, Space, Text, PrimaryButtonView } from 'components'

export const Page404View = ({ MenuButtonElement }) => {
  return (
    <Container>
      <div className={styles.container}>
        <Text h1 red heading center>
          Oops! This page doesn't exist!
        </Text>
        <Space h2 />
        {MenuButtonElement}
      </div>
    </Container>
  )
}

export const MenuButtonView = () => <PrimaryButtonView label="GO TO MENU" />

export const goToMenuButtonProps = {
  label: 'GO TO MENU',
}

const styles = {
  container: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '2rem',
    bottom: '12rem',
    padding: '2rem',
  }),
}
