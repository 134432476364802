import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Delivery from './Delivery'
import Summary from './Summary'
import Rewards from './Rewards'
import {
  CFButton,
  CFSelect,
  CFInput,
  CFModal,
  OrderStatus,
} from 'cf-web-app/components'
import { isEmpty } from 'lodash'
import { dispatch } from 'cf-web-app/store'
import { ThemeContext } from 'cf-web-app'
import { isCoordinateInDeliveryZone } from 'cf-utils'

class Cart extends Component {
  state = {
    order: {},
    submitting: false,
    showModal: false,
  }
  nameInput = React.createRef()
  phoneInput = React.createRef()
  render() {
    const { order, showModal, submitting } = this.state
    return (
      <ThemeContext.Consumer>
        {({ Cart, Components }) => (
          <>
            <Cart.CartView
              RewardsElement={
                <CFSelect selector={dispatch.user.getPointsWithPromoApplied}>
                  {points => <Rewards points={points} />}
                </CFSelect>
              }
              SummaryReceiptElement={
                isEmpty(order) ? this._renderSummary() : this._renderReceipt()
              }
            />
            <CFModal isOpen={showModal}>
              <Cart.UserDetailsModalView
                NameInputElement={
                  !dispatch.user.getName() && (
                    <CFInput
                      ref={this.nameInput}
                      maxLength="18"
                      onKeyPress={this._handleKeyPress}
                      defaultValue={this.name}
                      {...Cart.nameInputProps}
                    />
                  )
                }
                PhoneInputElement={
                  !dispatch.user.getPhoneNumber() && (
                    <CFInput
                      ref={this.phoneInput}
                      maxLength="10"
                      onKeyPress={this._handleKeyPress}
                      defaultValue={this.phoneNumber}
                      {...Cart.phoneInputProps}
                    />
                  )
                }
                CancelButtonElement={
                  <CFButton onClick={() => this.setState({ showModal: false })}>
                    <Components.PrimaryButtonView label="CANCEL" />
                  </CFButton>
                }
                SubmitButtonElement={
                  <CFButton
                    onClick={this._handleUserDetailsSubmitPress}
                    disabled={submitting}
                  >
                    <Components.PrimaryButtonView
                      label="SUBMIT"
                      disabled={submitting}
                    />
                  </CFButton>
                }
              />
            </CFModal>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
  _renderSummary = () => {
    return (
      <CFSelect
        selector={[
          dispatch.user.getIsCartEmpty,
          dispatch.restaurant.getWaitTime,
        ]}
      >
        {([isCartEmpty, waitTime]) => {
          return (
            <ThemeContext.Consumer>
              {({ Cart }) => (
                <Cart.SummaryContainerView
                  isCartEmpty={isCartEmpty}
                  orderType={dispatch.user.getOrderType()}
                  deliveryEnabled={dispatch.restaurant.getDeliveryEnabled()}
                  waitTime={waitTime}
                  SummaryElement={<Summary />}
                  NotesInputElement={
                    <CFSelect selector={dispatch.user.getNotes}>
                      {notes => (
                        <CFInput
                          multiline
                          value={notes}
                          onChange={this._handleNotesChange}
                          {...Cart.notesInputProps}
                        />
                      )}
                    </CFSelect>
                  }
                  DeliveryElement={<Delivery />}
                  SubmitButtonElement={
                    <CFSelect
                      selector={{
                        isLoggedIn: dispatch.user.getIsLoggedIn,
                        isPlacingOrder: dispatch.user.getIsPlacingOrder,
                        hasPayment: dispatch.user.getHasPayment,
                        last4: dispatch.user.getLast4,
                        total: dispatch.user.getCartTotal,
                      }}
                    >
                      {this._renderSubmitButton}
                    </CFSelect>
                  }
                />
              )}
            </ThemeContext.Consumer>
          )
        }}
      </CFSelect>
    )
  }
  _renderSubmitButton = ({
    isLoggedIn,
    isPlacingOrder,
    hasPayment,
    last4,
    total,
  }) => {
    if (!isLoggedIn) {
      return (
        <CFButton name="login" onClick={this._handleButtonPress}>
          <ThemeContext.Consumer>
            {({ Cart }) => <Cart.LoginButtonView />}
          </ThemeContext.Consumer>
        </CFButton>
      )
    }
    if (!hasPayment) {
      return (
        <CFButton
          name="payment"
          onClick={this._handleButtonPress}
          disabled={isPlacingOrder}
        >
          <ThemeContext.Consumer>
            {({ Cart }) => (
              <Cart.AddPaymentButtonView disabled={isPlacingOrder} />
            )}
          </ThemeContext.Consumer>
        </CFButton>
      )
    }
    if (total === 0) {
      return (
        <CFButton
          name="confirm"
          onClick={this._handleButtonPress}
          disabled={isPlacingOrder}
        >
          <ThemeContext.Consumer>
            {({ Cart }) => (
              <Cart.PlaceFreeOrderButtonView
                disabled={isPlacingOrder}
                isPlacingOrder={isPlacingOrder}
              />
            )}
          </ThemeContext.Consumer>
        </CFButton>
      )
    }
    return (
      <CFButton
        name="confirm"
        onClick={this._handleButtonPress}
        disabled={isPlacingOrder}
      >
        <ThemeContext.Consumer>
          {({ Cart }) => (
            <Cart.PlaceOrderButtonView
              disabled={isPlacingOrder}
              isPlacingOrder={isPlacingOrder}
              last4={last4}
            />
          )}
        </ThemeContext.Consumer>
      </CFButton>
    )
  }
  _renderReceipt = () => {
    const {
      orderType = '',
      completionTime,
      orderNumber,
      orderId,
      pointsEarned,
    } = this.state.order
    return (
      <ThemeContext.Consumer>
        {({ Cart }) => (
          <Cart.ReceiptView
            orderType={orderType}
            orderNumber={orderNumber}
            OrderStatusElement={<OrderStatus orderId={orderId} />}
            completionTimeFormatted={completionTime.format('h:mm A')}
            pointsEarned={pointsEarned}
            PlaceOrderButtonElement={
              <CFButton name="finish" onClick={this._handleButtonPress}>
                <Cart.FinishOrderButtonView />
              </CFButton>
            }
          />
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleNotesChange = e => {
    e.preventDefault()
    const { value } = e.target
    dispatch.user.setNotes(value)
  }
  _handleButtonPress = e => {
    e.preventDefault()
    const { name } = e.currentTarget
    const { history } = this.props
    if (name === 'login') {
      history.push(`/auth/cart`)
    } else if (name === 'payment') {
      history.push(`/payment/cart`)
    } else if (name === 'confirm') {
      this._handleConfirmOrderPress()
    } else if (name === 'finish') {
      history.push('/menu')
    }
  }
  _handleUserDetailsSubmitPress = e => {
    e.preventDefault()
    let name = ''
    if (!dispatch.user.getName()) {
      name = this.nameInput.current.value.trim()
      if (!name) {
        dispatch.notification.setMessage({
          message: 'Name cannot be empty!',
          level: 'error',
        })
        return
      }
    }
    let phoneNumber = ''
    if (!dispatch.user.getPhoneNumber()) {
      phoneNumber = this.phoneInput.current.value.trim()
      if (!phoneNumber) {
        dispatch.notification.setMessage({
          message: 'Phone Number cannot be empty!',
          level: 'error',
        })
        return
      }
    }
    this.setState({ submitting: true })
    dispatch.user
      .changeUserInfo({ name, phoneNumber })
      .then(() => {
        this._handleConfirmOrderPress()
        this.setState({ showModal: false })
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
      .finally(() => {
        this.setState({ submitting: false })
      })
  }
  _handleConfirmOrderPress = () => {
    if (!dispatch.restaurant.getIsStoreOpen()) {
      dispatch.notification.setMessage({
        message: 'Online ordering is closed!',
        level: 'error',
      })
      return
    }
    if (dispatch.restaurant.getDeliveryEnabled()) {
      const orderType = dispatch.user.getOrderType()
      if (orderType === '') {
        dispatch.notification.setMessage({
          message: 'Please choose pickup, delivery or dine-in!',
          level: 'error',
        })
        return
      }
    }
    if (
      dispatch.restaurant.getDeliveryEnabled() &&
      dispatch.user.getOrderType() === 'Delivery'
    ) {
      if (!dispatch.restaurant.getDeliveryOpen()) {
        dispatch.notification.setMessage({
          message: 'Delivery is closed!',
          level: 'error',
        })
        return
      }
      if (!dispatch.restaurant.getIsDeliveryHoursOpen()) {
        const deliveryHoursTime = dispatch.restaurant.getDeliveryHoursTime()
        if (deliveryHoursTime === 'Not Available') {
          dispatch.notification.setMessage({
            message: `Delivery is closed today!`,
            level: 'error',
          })
        } else {
          dispatch.notification.setMessage({
            message: `Delivery is open between ${deliveryHoursTime}!`,
            level: 'error',
          })
        }
        return
      }
      const minOrder = dispatch.restaurant.getMinOrder()
      if (
        dispatch.user.getCartSubTotal() - dispatch.user.getCartDiscount() <
        minOrder
      ) {
        dispatch.notification.setMessage({
          message: `Your subtotal must be greater than ${minOrder}!`,
          level: 'error',
        })
        return
      }
      const latLng = dispatch.user.getLatLng()
      const deliveryZone = dispatch.restaurant.getDeliveryZone()
      if (!isCoordinateInDeliveryZone(latLng, deliveryZone)) {
        dispatch.notification.setMessage({
          message: 'Sorry, your address is outside of our delivery area',
          level: 'error',
        })
        return
      }
    }
    const name = dispatch.user.getName()
    const phoneNumber = dispatch.user.getPhoneNumber()
    if (!name || !phoneNumber) {
      this.setState({ showModal: true })
      return
    }
    dispatch.user
      .createOrder()
      .then(order => {
        dispatch.notification.setMessage({
          message: 'Your order has been processed!',
          level: 'success',
        })
        this.setState({ order })
      })
      .catch(e => {
        dispatch.notification.setMessage({
          message:
            'Error processing order. Please call the restaurant to make your order',
          level: 'error',
        })
        console.warn(e)
      })
  }
}

export default withRouter(Cart)
